 <div *ngIf="Stage === paymentStage.Unpaid" (click)="onDonate()">
    <img width="68" class="stickybutton" height="95" src="../.././../assets/img/donate-1.png">
</div>

<!--<div class="buttons">
    <a routerLink="/Ceremony" class="stickybutton2 button mbl-btn is-info">
        <strong>Sign-up for <br>Live Ceremony</strong>
    </a>
</div> -->


<div class="modal"  [ngClass]="{ 'is-active' : isModalActive }">
    <div class="modal-background"></div>
    <div class="modal-card">
      <header class="modal-card-head"  style="background-color: #47017F!important;">
        <div class="container">
        <h1 style="text-align: center; font-size: 24px; color: white;">Donation Invite</h1>
      </div>
        <button class="delete" aria-label="close" (click)="toggleModal()"></button>
      </header>
      <section class="modal-card-body">
        <!-- Content ... -->
        <div *ngIf="Stage === paymentStage.SelectPayment">
          <p>Please contribute to download all resources at the BEING Abundance site.</p>
          <p>Donating zero (0) is welcome! Post donation, you will be redirected to the site with downloads enabled.</p>
         <br>
          <p>Your Donation:</p>
          <br>
          <div class="container">
            <div class="columns">
              <div class="column is-2">
                <p>Currency:</p>
              </div>
              <div class="column is-3">
                <div class="select">
                <select [(ngModel)]="currency" (change)="currencyChange(currency)" style = "text-transform:uppercase;">
                  <option  *ngFor="let c of currencies" [value]="c">{{c}}</option>
              </select>
            </div>
              </div>
              <div class="column is-1">
                <h2>Value:</h2>
              </div>
              <div class="column is-3">
                <div class="select">
                <select [(ngModel)]="price_id">
                  <option [value]="'price_zero'">0</option>
                  <option *ngFor="let p of prices" [value]="p.price_id">{{p.value}}</option>
              </select>
            </div>
              </div>
            </div>
            
  
          </div>
        </div>
      
      </section>
      <footer class="modal-card-foot">
        <button class="button " *ngIf="price_id === 'price_zero'; else StripePayment" (click)="onZeroPay()" style="background-color: #47017F!important; color: white;">Continue with Zero</button>
        <ng-template #StripePayment>
            <button class="button " (click)="onCheckout()" style="background-color: #47017F!important; color: white;">Proceed to Donate</button>
        </ng-template>
        <button class="button" (click)="toggleModal()" style="background-color: #47017F!important; color: white;">Cancel</button>
      </footer>
    </div>
  </div>