import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class ModalService {

  private modalObject = new Subject<any>();
  public modalState = this.modalObject.asObservable();

  private confirmObject = new Subject<any>();
  public deleteConfirmation = this.confirmObject.asObservable();

  constructor() { }

  public showModal(message) {
    this.modalObject.next(message);
  }

  public statusConfirmed(data) {
    this.confirmObject.next(data);
  }

}
