<div class="tabs is-centered is-boxed" style="margin-top:6rem;">
  <ul>
    <li class="tab is-active" (click)="openTab($event,'abundancebook')">
      <a>
        <span class="icon is-small"><i class="fas fa-book" aria-hidden="true"></i></span>
        <span>Abundance e-book </span>
      </a>
    </li>

    <li class="tab" (click)="openTab($event,'SelfattunementWheel')">
      <a>
        <span class="icon is-small"><i class="fas fa-film" aria-hidden="true"></i></span>
        <span>The Abundance Wheel</span>
      </a>
    </li>
    <!-- <li class="tab" (click)="openTab($event,'AbundanceVideos')">
        <a>
          <span class="icon is-small"><i class="fas fa-video" aria-hidden="true"></i></span>
          <span>Abundance Videos</span>
        </a>
      </li>
      <li class="tab" (click)="openTab($event,'Abundanceaudio')">
        <a>
          <span class="icon is-small"><i class="fas fa-music" aria-hidden="true"></i></span>
          <span>Abundance Audio</span>
        </a>
      </li>
      <li class="tab" (click)="openTab($event,'Abundanceanimations')">
        <a>
          <span class="icon is-small"><i class="far fa-file-alt" aria-hidden="true"></i></span>
          <span>Abundance animations</span>
        </a>
      </li> -->
    <li class="tab" (click)="openTab($event,'Symbols')">
      <a>
        <span class="icon is-small"><i class="far fa-file-alt" aria-hidden="true"></i></span>
        <span>The Abundance mandalas</span>
      </a>
    </li>
  </ul>
</div>

<section id="abundancebook" class="content-tab">
  <!-- <div class="container" style="text-align: center;">
    <div class="notification movement">
      <h1>Abundance book</h1>
    </div>
    <h2 class="movement-des">
      This is the paragraph where you can write more details about your product. Keep you user engaged by
      providing meaningful information. Remember that by this time, the user is curious, otherwise he wouldn't
      scroll to get here. Add a button if you want the user to see more.
    </h2>
    <div class="image-align">
      <figure class="image is-128x128" style="margin: auto;">

      </figure>
    </div>
    <div class="buttons abund-button">
      <button class="button is-warning ">
        <span class="icon">
          <i class="fab fa-amazon"></i>
        </span>
        <span>Get from Amazon</span>
      </button>
      <button class="button is-link">Donate And Access</button>
    </div>
  </div> -->
  <!-- <section class="hero is-medium has-carousel">
          <div class="my-element" class="hero-carousel">
              <div class="item-1">
                 foewjfowpgpr
              </div>
              <div class="item-2">
                gwrg
              </div>
              <div class="item-3">
           gwr
              </div>
          </div>
          <div class="hero-head"></div>
          <div class="hero-body"></div>
          <div class="hero-foot"></div>
      </section> -->
  <section>
    <div class="container" style="text-align: center;">
      <div class="notification movement" style="padding: 10px;">
        <h1>The 7 Mystical Laws of Abundance e-book</h1>
      </div>
      <h2 class="movement-des" style="width: 900px; padding: 10px; text-align: left;">
        Like never before, humanity is on a relentless pursuit of wealth and abundance. In that process, many are
        burning themselves and their relationships down, being left with a life of toil and ill-health.

        In this profound guide, Sujith has shared from the sages a radically different - yet simple - way to draw
        abundance into our lives. It starts with the recognition that the Universe is abundant, and It follows a set of
        laws in sharing that abundance.
      </h2>

      <h2 class="movement-des" style="width: 900px; padding: 10px; text-align: left;">
        Once we understand these seven powerful laws and align ourselves with the
        Universe, we will experience a radical shift in our relationship to wealth and abundance.

        Sujith - and many others before and after him - have applied these laws in their lives and seen abundance flow
        into their lives. Through his experiments, Sujith has freed himself from the incessant quest for abundance.
        Today he lives outside the concept of money.

        This practical guide is filled with numerous examples, anecdotes and exercises. Use it as your daily companion
        to shape a life of effortless abundance.
      </h2>


      <!-- <div class="image-align">
        <figure class="image is-128x128" style="margin: auto;">
          <img src="../../../assets/img/abudance.jpg">
        </figure>
      </div> -->
      <div class="buttons abund-button" *ngIf="Stage === paymentStage.Unpaid">
        <button class="button is-link" (click)="onDonate()">Download (English)</button>
        <button class="button is-link" (click)="onDonate()">Download (Italian)</button>
        <button class="button is-link" (click)="onDonate()">Download (Dutch)</button>
        <button class="button is-link" (click)="onDonate()">Download (German)</button>
      </div>
      <div *ngIf="Stage === paymentStage.Paid">
        <div class="columns">
          <div class="column">
            <div class="box">
              <article class="media">
                <div class="media-left">
                  <figure class="image is-128x128">
                    <img src="../../../assets/img/abudance.jpg" alt="Image" style="width: 90px;">
                  </figure>
                </div>
                <div class="media-content">
                  <div class="content">
                    <p>
                      <strong>You can download the English version below</strong>
                      <br>
                   
                    </p>
                  </div>
                  <nav class="level is-mobile">
                    <div class="level-left">
                      <a class="level-item" aria-label="reply">
                        <a class="button is-link" href="https://app.box.com/s/js8jvwtb1q36k2jwknhlq0b4ztr1t745">E-book (English)</a>
                      </a>
                    </div>
                  </nav>
                </div>
              </article>
            </div>
          </div>
          <div class="column">
            <div class="box">
              <article class="media">
                <div class="media-left">
                  <figure class="image is-128x128">
                    <img src="../../../assets/img/abudance.jpg" alt="Image" style="width: 90px;">
                  </figure>
                </div>
                <div class="media-content">
                  <div class="content">
                    <p>
                      <strong>You can download the Italian version below</strong>
                      <br>
                   
                    </p>
                  </div>
                  <nav class="level is-mobile">
                    <div class="level-left">
                      <a class="level-item" aria-label="reply">
                        <a class="button is-link" href="https://app.box.com/s/yqoxds0o8vo6o0ooorz2r305iwu5y6p8">E-book (Italian)</a>
                      </a>
                    </div>
                  </nav>
                </div>
              </article>
            </div>
          </div>

        </div>

        <div class="columns">
          <div class="column">
            <div class="box">
              <article class="media">
                <div class="media-left">
                  <figure class="image is-128x128">
                    <img src="../../../assets/img/abudance.jpg" alt="Image" style="width: 90px;">
                  </figure>
                </div>
                <div class="media-content">
                  <div class="content">
                    <p>
                      <strong>You can download the Dutch version below</strong>
                      <br>
                   
                    </p>
                  </div>
                  <nav class="level is-mobile">
                    <div class="level-left">
                      <a class="level-item" aria-label="reply">
                        <a class="button is-link" href="https://app.box.com/s/jlagg4d06drv7owhlwxezlki0590jae0">E-book (Dutch)</a>
                      </a>
                    </div>
                  </nav>
                </div>
              </article>
            </div>
          </div>
          <div class="column">
            <div class="box">
              <article class="media">
                <div class="media-left">
                  <figure class="image is-128x128">
                    <img src="../../../assets/img/abudance.jpg" alt="Image" style="width: 90px;">
                  </figure>
                </div>
                <div class="media-content">
                  <div class="content">
                    <p>
                      <strong>You can download the German version below</strong>
                      <br>
                   
                    </p>
                  </div>
                  <nav class="level is-mobile">
                    <div class="level-left">
                      <a class="level-item" aria-label="reply">
                        <a class="button is-link" href="https://app.box.com/s/q2uciz6j9zcfjbyzqgx44qahsh7xns32">E-book (German)</a>
                      </a>
                    </div>
                  </nav>
                </div>
              </article>
            </div>
          </div>
        </div>

      
    </div>
    </div>

  </section>

</section>
<section id="SelfattunementWheel" class="content-tab" style="display:none">
  <div class="container" style="text-align: center;">
    <div class="notification movement">
      <h1>The Abundance Wheel</h1>
    </div>
    <h2 class="movement-des">
      The Abundance Wheel lays out all the areas of life where any human being pursues Abundance. Use this wheel to
      determine which areas (preferably maximum of 3) are you currently open to welcome Abundance to. In each of the
      areas of life where you pursue Abundance, give yourself a score between 1 to 10 based on what is the level of
      Abundance you have in your life today (based on your current reality). An exercise like this using the Abundance
      wheel will give you a sense of the next leap of Abundance you’re ready for.
    </h2>
    <div class="image-align">
      <figure class="image is-128x128" style="margin: auto;">
        <img src="../../../assets/img/Wheel.png">
      </figure>
    </div>
    <div class="buttons abund-button" *ngIf="Stage === paymentStage.Unpaid">
      <button class="button is-link"  (click)="onDonate()">Download</button>
    </div>
    <div class="buttons abund-button" *ngIf="Stage === paymentStage.Paid">
      <a class="button is-link" href="https://app.box.com/s/ktntli1qxfkomkshh0sdja8hj03vkuas">The Abundance Wheel</a>
  </div>
  </div>

</section>
<!-- <section id="AbundanceVideos" class="content-tab" style="display:none">
  
    <div class="notification movement">
      <h1>Abundance Videos</h1>
    </div>
    <div class="columns" style=" padding: 40px;">
      <div class="column">
      <div class="card">
          <div class="card-image">
              <figure class="image is-square">
                  <iframe class="has-ratio" width="640" height="360" src="https://www.youtube.com/embed/5vSjr0ZS3Hw" frameborder="0" allowfullscreen></iframe>
                </figure>
          </div>
          <div class="card-content">
            <div class="item__title">
              Mon titre 5
            </div>
            <div class="item__description">
              Ici une petite description pour tester le slider
            </div>
          </div>
        </div>
      </div>
      <div class="column">
        <div class="card">
          <div class="card-image">
              <figure class="image is-square">
                  <iframe class="has-ratio" width="640" height="360" src="https://www.youtube.com/embed/7BSl7phXhAQ" frameborder="0" allowfullscreen></iframe>
                </figure>
          </div>
          <div class="card-content">
            <div class="item__title">
              Mon titre 5
            </div>
            <div class="item__description">
              Ici une petite description pour tester le slider
            </div>
          </div>
        </div>
      </div>
  
      <div class="column">
        <div class="card">
          <div class="card-image">
              <figure class="image is-square">
                  <iframe class="has-ratio" width="640" height="360" src="https://www.youtube.com/embed/Hq6dAK4fCZ8" frameborder="0" allowfullscreen></iframe>
                </figure>
               
          </div>
          <div class="card-content">
            <div class="item__title">
              Mon titre 5
            </div>
            <div class="item__description">
              Ici une petite description pour tester le slider
            </div>
          </div>
        </div>
      </div>
      </div>
    <h2 class="movement-des">
      This is the paragraph where you can write more details about your product. Keep you user engaged by
      providing meaningful information. Remember that by this time, the user is curious, otherwise he wouldn't
      scroll to get here. Add a button if you want the user to see more.
    </h2>
  
  
  
  
  </section>
  <section id="Abundanceaudio" class="content-tab" style="display:none">
  
    <div class="notification movement">
      <h1>Abundance Audio</h1>
    </div>
    <h2 class="movement-des">
      <iframe style="height: 200px;" width="100%" height="600" scrolling="no" frameborder="no" allow="autoplay"
        src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/817715620&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"></iframe>
      <div
        style="font-size: 10px; color: #cccccc;line-break: anywhere;word-break: normal;overflow: hidden;white-space: nowrap;text-overflow: ellipsis; font-family: Interstate,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Garuda,Verdana,Tahoma,sans-serif;font-weight: 100;">
        <a href="https://soundcloud.com/ammth" title="aMMtH" target="_blank"
          style="color: #cccccc; text-decoration: none;">aMMtH</a> · <a
          href="https://soundcloud.com/ammth/ep-3-the-7-mystical-laws-of-abundance-with-sujith-ravindran"
          title="Ep. 3 - The 7 Mystical Laws of Abundance with Sujith Ravindran" target="_blank"
          style="color: #cccccc; text-decoration: none;">Ep. 3 - The 7 Mystical Laws of Abundance with Sujith
          Ravindran</a></div>
    </h2>
  
  
  
  
  </section>
  <section id="Abundanceanimations" class="content-tab" style="display:none">
    <div class="container" style="text-align: center;">
      <div class="notification movement">
        <h1>Abundance animations</h1>
      </div>
      <div class="columns">
        <div class="column">
        <div class="card">
            <div class="card-image">
                <figure class="image is-square">
                    <iframe class="has-ratio" width="640" height="360" src="https://www.youtube.com/embed/ut8IsLLn7qY" frameborder="0" allowfullscreen></iframe>
                  </figure>
            </div>
            <div class="card-content">
              <div class="item__title">
                Mon titre 5
              </div>
              <div class="item__description">
                Ici une petite description pour tester le slider
              </div>
            </div>
          </div>
        </div>
        <div class="column">
          <div class="card">
            <div class="card-image">
                <figure class="image is-square">
                    <iframe class="has-ratio" width="640" height="360" src="https://www.youtube.com/embed/7BSl7phXhAQ" frameborder="0" allowfullscreen></iframe>
                  </figure>
            </div>
            <div class="card-content">
              <div class="item__title">
                Mon titre 5
              </div>
              <div class="item__description">
                Ici une petite description pour tester le slider
              </div>
            </div>
          </div>
        </div>
  
        <div class="column">
          <div class="card">
            <div class="card-image">
                <figure class="image is-square">
                    <iframe class="has-ratio" width="640" height="360" src="https://www.youtube.com/embed/Hq6dAK4fCZ8" frameborder="0" allowfullscreen></iframe>
                  </figure>
                 
            </div>
            <div class="card-content">
              <div class="item__title">
                Mon titre 5
              </div>
              <div class="item__description">
                Ici une petite description pour tester le slider
              </div>
            </div>
          </div>
        </div>
        </div>
      <h2 class="movement-des">
        This is the paragraph where you can write more details about your product. Keep you user engaged by
        providing meaningful information. Remember that by this time, the user is curious, otherwise he wouldn't
        scroll to get here. Add a button if you want the user to see more.
      </h2>
      
    </div>
  
  </section> -->
<section id="Symbols" class="content-tab" style="display:none">
  <div class="container" style="text-align: center;">
    <div class="notification movement">
      <h1>The Abundance mandalas</h1>
    </div>
    <h2 class="movement-des">
      Welcome to the Abundance Mandalas. Please take a few minutes to watch this <a href="https://youtu.be/d7BigqpcTr0" target="_blank">video</a> on how best to use the Mandalas
      to maximise the joy of colouring and attract abundance into your lives. While colouring, please hold the
      corresponding law in your awareness and colour using the one colour assigned to each Mandala.
    </h2>
    <div class="image-align">
      <figure class="image is-128x128" style="margin: auto;">
        <img src="../../../assets/img/mandala.PNG">
      </figure>
    </div>
    <div class="buttons abund-button"  *ngIf="Stage === paymentStage.Unpaid">
      <button class="button is-link" (click)="onDonate()">Download</button>
    </div>
    <div class="buttons abund-button" *ngIf="Stage === paymentStage.Paid">
      <a class="button is-link" href=" https://app.box.com/s/qu187cbyfmt0wp48ldwbop437b0en78v">The Abundance Mandala</a>
  </div>
  </div>

</section>

<div class="modal"  [ngClass]="{ 'is-active' : isModalActive }">
  <div class="modal-background"></div>
  <div class="modal-card">
    <header class="modal-card-head"  style="background-color: #47017F!important;">
      <div class="container">
      <h1 style="text-align: center; font-size: 24px; color: white;">Donation Invite</h1>
    </div>
      <button class="delete" aria-label="close" (click)="toggleModal()"></button>
    </header>
    <section class="modal-card-body">
      <!-- Content ... -->
      <div *ngIf="Stage === paymentStage.SelectPayment">
        <p>Please contribute to download all resources at the BEING Abundance site.</p>
        <p>Donating zero (0) is welcome! Post donation, you will be redirected to the site with downloads enabled.</p>
       <br>
        <p>Your Donation:</p>
        <br>
        <div class="container">
          <div class="columns">
            <div class="column is-2">
              <p>Currency:</p>
            </div>
            <div class="column is-3">
              <div class="select">
              <select [(ngModel)]="currency" (change)="currencyChange(currency)" style = "text-transform:uppercase;">
                <option  *ngFor="let c of currencies" [value]="c">{{c}}</option>
            </select>
          </div>
            </div>
            <div class="column is-1">
              <h2>Value:</h2>
            </div>
            <div class="column is-3">
              <div class="select">
              <select [(ngModel)]="price_id">
                <option [value]="'price_zero'">0</option>
                <option *ngFor="let p of prices" [value]="p.price_id">{{p.value}}</option>
            </select>
          </div>
            </div>
          </div>
          

        </div>
      </div>
    
    </section>
    <footer class="modal-card-foot">
      <button class="button " *ngIf="price_id === 'price_zero'; else StripePayment" (click)="onZeroPay()" style="background-color: #47017F!important; color: white;">Continue with Zero</button>
      <ng-template #StripePayment>
          <button class="button " (click)="onCheckout()" style="background-color: #47017F!important; color: white;">Proceed to Donate</button>
      </ng-template>
      <button class="button" (click)="toggleModal()" style="background-color: #47017F!important; color: white;">Cancel</button>
    </footer>
  </div>
</div>