<div class="container card layout" style="margin-top:10vh;">
    <p><strong class="heading">[A 7-DAYS RETREAT TO LIVE, BREATHE & BECOME ABUNDANCE]</strong></p>
    <p>Look around. There is abundance everywhere. The green, the water, the air, the birds, the sunshine…………….we live
        in a plentiful world.<br />
        <br /> Yet…………because of the pandemic, or the geopolitical crisis, or because of the material things we miss in
        our lives, or because of various other events around us, many of us have allowed the joy of living to seep out
        of our lives. Many of us feel blocked from following our hearts. With some of us, our sense of optimism and
        possibilities has faded.
    </p>
    <p>
        To have abundance, you must see abundance. To see abundance, you must feel abundance within. <br />
        This truth is laid out in the popular global treatise on abundance, The Seven Mystical Laws of Abundance.
    </p>
    <p><strong class="heading"><br /> <br />ABOUT THE RETREAT</strong></p>
    <p>
        Abundance starts with the recognition that the Universe is abundant. Once we align ourselves with the Universe,
        we will experience a radical shift in our relationship to wealth and abundance. The 7 days ‘You are Abundance’
        retreat is this space of alignment, of spontaneous activation of those abundance keys within you.
        <br>
        During those days, we will look at the areas of life where we are ready to create abundance. We will undertake a
        deep immersion into the wisdom and experience of abundance that lies submerged within each one of us, and we
        will forge intimacy with the “all-ness” that we are. We will also completely unblock the energy flow within us
        that is preventing us from experiencing the “all-ness” of life.
        Through a series of practices, exercises and processes we will create our own Abundance Manifesto that will
        govern the arc of our lives going forward.
    </p>

    <p><strong class="heading"><br /> <br /> Intentions from the retreat; </strong></p>
    <ul>
        <li>
            - Awaken the consciousness of abundance and gratitude in our lives
        </li>
        <li>
            - Living our innate abundance nature by the time we leave the 7 days
        </li>
        <li>
            - Create abundance within our personal & professional lives, and in all our endeavours
        </li>
    </ul>
   <p>[FOR MORE DETAILS;<a href="https://www.eventbrite.ca/e/you-are-abundance-a-7-days-retreat-to-live-breathe-and-become-abundance-tickets-822492044847">https://www.eventbrite.ca/e/you-are-abundance-a-7-days-retreat-to-live-breathe-and-become-abundance-tickets-822492044847</a> </p>


    <p>---------------------------------------------</p>

    <p>“JE BENT OVERVLOED” - Een 7-daagse retraite om te leven, ademen en overvloed te worden<br />
        <br /> Sluit je aan bij Sujith Ravindran in Nederland voor een diep, ontspannend, 7-daags zomerverblijf om te
        leven, ademen en Overvloed te worden.
    </p>
    <p>
        Kijk om je heen. Er is overal overvloed. Het groen, het water, de lucht, de vogels, de zon…We leven in een
        wereld van overvloed.
        Maar…… vanwege de pandemie, of de geo-politieke crisis, of vanwege de materiële dingen die we in ons leven
        missen, of vanwege verschillende andere gebeurtenissen om ons heen, hebben velen van ons de vreugde van het
        leven uit ons lichaam af gescheiden. Velen van ons voelen zich geblokkeerd in het volgen van ons hart. Bij
        sommigen van ons, is ons gevoel van optimisme en mogelijkheden vervaagd.
    </p>
    <p>
        Om overvloed te hebben, moet je overvloed zien. Om overvloed te zien, moet je de overvloed van binnen voelen.
        <br>
        Deze waarheid wordt uiteengezet in de populaire mondiale verhandeling over overvloed, ‘De 7 mystieke wetten van overvloed’.
    </p>
    <p>
        Overvloed begint met de erkenning dat het universum overvloedig is. Zodra we ons op één lijn brengen met het universum, zullen we een radicale verschuiving ervaren in onze relatie tot rijkdom en overvloed. De 7-daagse ‘Jij bent Overvloed’-retraite is deze ruimte van afstemming, van spontane activering van die overvloedsleutels in jou.
        </p>
        <p>
            Tijdens die dagen zullen we kijken naar de gebieden van het leven waar we klaar zijn om overvloed te creëren. We zullen een diepe onderdompeling uitvoeren in de wijsheid en ervaring van overvloed die in ieder van ons verborgen ligt, en we zullen intimiteit smeden met het ‘al-zijn’ dat we zijn. We zullen ook de energiestroom in ons volledig deblokkeren die ons ervan weerhoudt de ‘heelheid’ van het leven te ervaren.
        </p>
        <p>
            Door middel van een reeks oefeningen en processen zullen we ons eigen Overvloedsmanifest creëren dat de boog van ons leven in de toekomst zal bepalen.
        </p>

    <p><strong class="heading"><br /> <br />Intenties van de retraite; </strong></p>
    <ul>
        <li>
            - Maak het bewustzijn van overvloed en dankbaarheid in ons leven wakker
        </li>
        <li>
            - Onze aangeboren overvloed-natuur leven tegen de tijd dat we de 7 dagen verlaten
        </li>
        <li>
            - Creëer overvloed in ons persoonlijke en professionele leven, en in al onze inspanningen
        </li>
    </ul>
    <p>SCHRIJF JE IN:<a href="https://www.eventbrite.ca/e/you-are-abundance-a-7-days-retreat-to-live-breathe-and-become-abundance-tickets-822492044847">https://www.eventbrite.ca/e/you-are-abundance-a-7-days-retreat-to-live-breathe-and-become-abundance-tickets-822492044847</a> </p>

</div>