import { Component, OnInit, Input } from '@angular/core';
import { ModalService } from 'src/app/services/modal.service';
import { ModalComponent } from '../../components/modal/modal.component';
import { ModalRef } from '../../helper/modal-ref.model';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { ProfileService } from 'src/app/services/profile.service';
import { DatePipe } from '@angular/common';
import { Route } from '@angular/compiler/src/core';
import { Router } from '@angular/router';
@Component({
  selector: 'app-card-game',
  templateUrl: './card-game.component.html',
  styleUrls: ['./card-game.component.css']
})
export class CardGameComponent implements OnInit {
  ModalComponent: any;

  constructor(public modalSvc: ModalService, public profilesvc:ProfileService, public router :Router, public datepipe :DatePipe) { }
  status: boolean = false;
  profileData;
  todaydate
  ngOnInit(): void {

  }

  showcardinRow() {
    this.status = !this.status;
  }

  onDonation() {
    this.modalSvc.showModal({ active: true });
    this.modalSvc.deleteConfirmation.pipe(take(1)).
      subscribe((data) => {
        if (data == true) {
          alert('hello');
        }
      })
  }

  activateGame(){
    this.checktodayEligibilty();
  }

  checktodayEligibilty() {
    var arr = { email: 'dineshkannab@gmail.com' };
    this.profilesvc.getprofile(arr).subscribe(data => {
      var profilearr = data.data;
      this.profileData = profilearr[0];

      var latest_date = new Date();
      this.todaydate = this.datepipe.transform(latest_date, 'dd-MM-yyyy');

      if (this.profileData.last_opened_date === this.todaydate) {
        alert('helo');

    // testing starts
        // swal({
        //   title: "Are you sure?",
        //   text: "Need to draw a one more card",
        //   icon: "warning",
        //   dangerMode: true,
        //   buttons: ["No", true],
        // })
        // .then((willDelete) => {
        //   if (willDelete) {
        //     this.proceedtoPlay(this.profileData);
        //   } else {
        //     swal("Your card is safe for tomorrow");
        //   }
        // });
        // Testing end
      }
      else {
        this.proceedtoPlay(this.profileData);
      }

    })
  }

  proceedtoPlay(data){

  this.getCardNumber(data);

  }

  getCardNumber(CardprofileData){
    this.profileData=CardprofileData;
    var openedCards = this.profileData.card_open

    var max = this.profileData.cardWeek
    var min = this.profileData.cardWeek
    var cardvalue = Math.floor(Math.random() * (max - min + 1) + min);
    
    if(this.profileData.card_open){
      
      if (openedCards.includes(cardvalue)) {
        // alert("exists")
        this.getCardNumber(CardprofileData);
      }
      else{
        this.router.navigateByUrl('/todayscard/'+[cardvalue]);
        // return cardvalue; 
      }
    }

  }



}
