
<div class="container card layout">
    <div style="padding: 3rem 2rem 2rem; text-align: center;">
        <h1><strong>The Abundance Pledge</strong></h1>
        <h1><strong>As an ambassador of abundance, I pledge to honour this covenant</strong></h1>
    </div>
    <div class="container" style="padding: 0rem 6rem 0rem">
        <ul>
            <li>In every way I can, I commit to unconditionally give. In cash or in kind, I will engage in one act of giving
                each day. When I give, I will give for the joy of giving, and not for the pleasure of the reward. The
                thought of rewards will not hold me back from giving, aware that rewards that I am due shall follow some day
                through channels unexpected.</li>
            <li>In every way I can, I commit to unconditionally receive. And when I receive, I will receive with the glee of
                a child, while acknowledging the Universe for supporting me with abundance to live my purposefully.</li>
            <li>I will celebrate every reward by paying it forward, for I am aware that when I pay it forward it multiplies
                many folds and touches countless lives.</li>
            <li>I pledge to embody the inner state of abundance. Every day I will remind myself and those around me that
                there is enough to go around, and that I am always provided for. I pledge to live by the gratitude of what
                there is rather than live by the lack of what there is not. I commit to awaken and live the abundant spirit
                of the child within me.</li>
            <li>Above all, I will remember that true wealth is inexhaustible and grows as it flows. I will take it as a
                special obligation to all my fellow human beings to keep wealth flowing, bringing prosperity to all.</li>
        </ul>
    </div>
    <div class="container" style="padding: 3rem;text-align: center;">
        <!-- <button class="button is-link">I Take the Abundance Pledge</button>
        <h2 style="padding:1rem;">Number of Pledgers: 000</h2> -->
        <button class="button is-link" (click)="downloadMyFile()">Download Pledge</button>
    </div>
</div>

