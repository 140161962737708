import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-constitution',
  templateUrl: './constitution.component.html',
  styleUrls: ['./constitution.component.css']
})
export class ConstitutionComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
