<section class="section" style="margin-top: 30px;">
    <div class="container" style="text-align: center;">
        <div class="notification movement">
            <h1>Our Team</h1>
        </div>
        <h2 class="movement-des">
            <h6>Meet our fellow Abundance travellers!</h6>
        </h2>
    </div>
</section>
<section id="team">
    <div class="card">
        <img src="../../../assets/img/sujithbg.png" alt="" />
        <div class="data">
            <h2>Sujith</h2>
            <p> Sujith Ravindran is a contemporary mystic and a serial author. He helps leaders, institutions and
                movements in the social, political and business domains access unforeseen realms of awareness and fulfil
                their true reason for existence. He is the author of The 7 Mystical Laws of Abundance, and the founder
                of the BEING Abundance Movement. For work around spiritual upliftment, he was awarded the honour of
                Ambassador of Peace in 2012 by the Council of Assissi, Italy.
            </p>
        </div>
    </div>
    <div class="card">
        <img src="../../../assets/img/Steve.jpg" alt="" />
        <div class="data">
            <h2>Steve</h2>

            <p>Living and born in Belgium. Father of 2 fantastic childeren. Professionally active as a sales person.
                Briony invited me to follow an online training about abundance with Sujith. The 7 mystical laws of
                abundance really inspired me and touched my heart. It's not just new knowledge for me, I really try to
                implement it in my life, step by step. So honored to be part of this team. </p>
        </div>
    </div>
    <div class="card">
        <img src="../../../assets/img/Patricia.JPG" alt="" />
        <div class="data">
            <h2>Patricia</h2>

            <p>Consciousness worker, Abundance networker, angel and transformer. Been active in education and in
                business. Writing, communication, abundance, shamanism, being creative with color are some of my
                passions.
                Life is full of twists and turns and surprises. From my experiences and wisdom, I like to support
                organizations and individual adults and children.</p>
        </div>
    </div>
    <div class="card">
        <img src="../../../assets/brionybg.jpg" alt="" />
        <div class="data">
            <h2>Briony</h2>

            <p>Briony is a visionary, international entrepreneur, trainer and creator of the Quantum Shift method. She
                is an expert in business and educational innovation and trains entrepreneurs in business expansion and
                quantum thinking. She founded an innovative primary school in Belgium and was several years director.
                She launched during the last 12 years various initiatives with events up to 3000 visitors in the field
                of education and business. She is singer-songwriter, author of 'Unlimited Growth of your company' and
                multiple children books.</p>
        </div>
    </div>
    <div class="card">
        <img src="../../../assets/img/Navneesh.jpg" alt="" />
        <div class="data">
            <h2>Navneesh</h2>

            <p>While growing up as a child, the inner urge in me to know Who am I? Why am I here? was very intense.
                Slowly, many great masters and teacher's began showing up who gently held my hand to weave a new path. A
                path that was in alignment with the calling of my heart. In their presence and shared silence, the
                urgency and the need to find answers to my questions simply dissolved. Today, all my offerings for the
                collective good of the humanity emerge from the love that so many beings have showered over me
                especially my grandmother</p>
        </div>
    </div>

    <div class="card">
        <img src="../../../assets/img/Ludo.JPG" alt="" />
        <div class="data">
            <h2>Ludo</h2>

            <p>Ludo Simons, Healing stream of the heart, dancing heart and Lotvid. In my life I received these tree
                names in budism, sjamanism and Sufism. I like to embody new counciousness and to lead from the heart.
                Best things are in silence and without a name tag on! My biggest contribution is anywhere on this
                planet. I try to become the best version of me by listening to nature, my ancestors and my vision.</p>
        </div>
    </div>

    <div class="card">
        <img src="../../../assets/img/_ThomasDachsel_.jpg" alt="" />
        <div class="data">
            <h2>Thomas</h2>

            <p>My name is Thomas, I live with my wife in Germany and I am the German translator of Sujith's books. I
                have been on my spiritual path since the 1990's, traveled to the US more than 20 times for varied
                events, performed as the English-German translator at many spiritual events in Germany. About twenty
                years ago, I became active in abundance-oriented spiritual activism and when the current pandemic
                started, I searched for a way to express my spirituality in an international setting and feel very
                honored to have been active with the Being Abundance movement from the get-go. </p>
        </div>
    </div>
    <div class="card">
        <img src="../../../assets/img/Wouter.JPG" alt="" />
        <div class="data">
            <h2>Wouter </h2>

            <p>Wouter Opdenacker was raised in Rekem (Limburg) and currently lives in Bruges (West). He studied at the
                University of Ghent and is currently serving his team all over Flanders as manager of the front office
                of the Flemish & Neutral Health Insurance Fund. Where health and finances meet, he tries to provide it
                as best he can for everyone who needs it. He is also passionate about men's work and personal and
                spiritual growth.</p>
        </div>
    </div>

    <div class="card">
        <img src="../../../assets/img/Koen.jpeg" alt="" />
        <div class="data">
            <h2>Koen </h2>

            <p>Koen is a journalist and founder of Local Heroes. Working more than twenty years as a news reporter and
                human interest storyteller, Koen knows that there is more to tell then what journalism is portrayed out
                there... There are so many inspiring people with heartfelt initiatives who change society daily through
                kindness. You can now encounter them through Koen's podcast "Local Heroes," where ordinary people share
                extraordinary deeds around the globe!</p>
        </div>
    </div>
    <div class="card">
        <img src="../../../assets/img/Dinesh.jpg" alt="" />
        <div class="data">
            <h2>Dineshkanna </h2>
            <p>A google-aspirant, Dinesh is an UI developer in an IT MNC with fascinations on Product development and
                full-stack development. By being part of the Abundance Movement, he wants to give his gift of website
                development to reach dozens of people across the globe </p>
        </div>
    </div>
</section>