<body> 
    <script src="https://cdn.jsdelivr.net/npm/bulma-carousel@4.0.4/dist/js/bulma-carousel.min.js"></script>
<div style="min-height: 100vh;position: relative;">
    <app-header></app-header>
    <app-donation></app-donation>
    <router-outlet (activate)="onActivate($event)">
    </router-outlet>
    <app-modal></app-modal>
</div>
<app-footer></app-footer>
</body>
