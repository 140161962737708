import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-prod-layout',
  templateUrl: './prod-layout.component.html',
  styleUrls: ['./prod-layout.component.css']
})
export class ProdLayoutComponent implements OnInit {

  title = 'abundance1';

  // const sound = new Howl({
  //   src: ['../assets/mp3/bgaudio.mp3', '../assets/mp3/bgaudio.mp3']
  // });
  onActivate(event) {
    window.scroll(0, 0);
  }
  ngOnInit() {
    // Play the sound.
  
}
}
