import { Component, Inject, OnInit } from '@angular/core';
// import { DataService } from 'src/app/services/data.service';
// import { AuthenticationService } from 'src/app/services/authentication.service';
import { DynamicDialogConfig } from '../../components/dynamic-dialog/dynamic-dialog.config';
import { DynamicDialogService } from '../dynamic-dialog/dynamic-dialog.service';
import { RegisterComponent } from '../register/register.component';
import { AuthenticationService } from '../../services/authentication.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { DOCUMENT } from '@angular/common';
import { CookieService } from 'ngx-cookie';

import "src/assets/addon.js";
import { ShareService } from 'ngx-sharebuttons';
import { of } from 'rxjs';
import { tap } from 'rxjs/operators';
declare var ready: any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  menuActive = false;
  accountActive = false;
  showModal: boolean = false;
  isLoggedIn = false;
  userId = null;
 // constructor(
  //  private authSvc: AuthenticationService, private route: Router, private share: ShareService
  //) { }
  constructor(
    public auth: AuthService,
    private route: Router,
    private cookieService: CookieService,
    
    @Inject(DOCUMENT) private doc: Document
  ) {}
  loginWithRedirect() {
    this.auth.loginWithRedirect();
    
  }

  logout() {
    this.auth.logout({ returnTo: this.doc.location.origin });
    localStorage.removeItem("userId")
  }
  


  ngOnInit() {
    
    
    document.addEventListener('DOMContentLoaded', () => {
     
      // Get all "navbar-burger" elements
      const $navbarBurgers = Array.prototype.slice.call(document.querySelectorAll('.navbar-burger'), 0);

      // Check if there are any navbar burgers
      if ($navbarBurgers.length > 0) {

        // Add a click event on each of them
        $navbarBurgers.forEach(el => {
          el.addEventListener('click', () => {

            // Get the target from the "data-target" attribute
            const target = el.dataset.target;
            const $target = document.getElementById(target);

            // Toggle the "is-active" class on both the "navbar-burger" and the "navbar-menu"
            el.classList.toggle('is-active');
            $target.classList.toggle('is-active');

          });
        });
      }

    });

   /**  this.authSvc.checkToken();
    this.authSvc.authenticationState.subscribe(state => {
      console.log('chaging state ', state);
      this.isLoggedIn = state;
    })

    this.share.addButton('customButton', {
      type: 'customButton',
      text: 'My Custom Button',
      icon: ['fas', 'fas-comments-dollar'],
      params: {
        // define the needed parameters here
      },
      // If the button uses a custom function, then set your custom function
      func: () => of({}).pipe(tap(() => alert('Custom button works!')))
    });
  }


  //logout() {
   // this.authSvc.logout();
  //}

  registerModal() {
    this.route.navigateByUrl('/login');


  }**/
  }

  cardGame() {
    this.route.navigateByUrl('/cardgame');
  }

}
