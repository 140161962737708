import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { DynamicDialogRef } from '../dynamic-dialog/dynamic-dialog-ref';
import { DynamicDialogConfig } from '../dynamic-dialog/dynamic-dialog.config';
import { AuthenticationService } from '../../services/authentication.service';
@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {

  signupForm: FormGroup;
  loginForm: FormGroup;
  submit = false;
  returnUrl: String;
  showlogin: boolean = false;
  signupArray = []
  constructor(private readonly dialogRef: DynamicDialogRef<string>, private readonly dialogConfig: DynamicDialogConfig,
    private formBuilder: FormBuilder, private authScv: AuthenticationService) {
  }

  ngOnInit(): void {
    this.signupForm = this.formBuilder.group({
      firstName: ['', [Validators.required]],
      email: ['', [Validators.required]],
      password: ['', [Validators.required]],
      mobileNumber: ['', [Validators.required]],
      lastName: [''],
      userName: [''],

    })
    this.loginForm = this.formBuilder.group({
      email: '',
      password:''
  })
  }

  registermode() {
    this.showlogin =  !this.showlogin;
  }

  onsubmitLogin() {
    this.authScv.login(this.loginForm.value)
}

}
