import { Component, OnInit, AfterViewInit, Inject } from '@angular/core';
import { Router } from '@angular/router';
import bulmaAccordion from 'bulma-accordion';
import { DynamicDialogConfig } from '../dynamic-dialog/dynamic-dialog.config';
import { DynamicDialogService } from '../dynamic-dialog/dynamic-dialog.service';
import { ModalComponent } from '../modal/modal.component';
import { RegisterComponent } from '../register/register.component';
import { Howl, Howler } from 'howler';
import { AuthService } from '@auth0/auth0-angular';
import { DOCUMENT } from '@angular/common';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit, AfterViewInit {
  bulmaCarousel;
  pledgeActive: boolean = false;
  userId=null;
  constructor(private readonly dynamicDialogService: DynamicDialogService, private router: Router,public auth: AuthService ,@Inject(DOCUMENT) private doc: Document,) { }
  // sound = new Howl({
  //   src: ['../assets/mp3/bgaudio.mp3'],
  //   loop: false, autoplay: true,
  //   volume: 0.5, html5: true
  // });'
  loginWithRedirect() {
   
    if(this.userId==null)
    //if(this.auth.user$._isScalar==false)
    {
     this.auth.loginWithRedirect();
      
    }
    else{
     this.router.navigate(['/home'])
    }
    
  }
  ngOnInit() {
    this.auth.user$.subscribe(
      (profile) => {
        this.userId = profile.sub;
      }
    )
    // this.sound.play();
    // document.addEventListener('DOMContentLoaded', function () {
    //   $("#server").click(function() {
    //     $("#server-content").toggleClass('is-hidden');
    //   });

    //   $("#server1").click(function() {
    //     $("#server-content1").toggleClass('is-hidden');
    //   });

    // });
  }

  ngAfterViewInit() {
    var accordions = bulmaAccordion.attach();
    // this.sound.play();
  }

  navigateResources() {
    this.router.navigateByUrl('resources');
  }
  ActivePledge() {
    // const dialogConfig = new DynamicDialogConfig();
    // dialogConfig.header = 'Register for Abundance Journey';
    // const ref = this.dynamicDialogService.open<string>(ModalComponent, dialogConfig);
    // ref.afterClosed.subscribe(data => {
    //   console.log('close', data);
    // });
    // this.pledgeActive = false;
    this.router.navigateByUrl('take-the-pledge');
  }

  buttonSelected() {

  }
  ngOnDestroy() {
    // this.sound.unload()
  }

}
