<div class="container container card layout" style="padding: 40px;">
<p><strong><u>Constitution of the BEING Abundance movement</u></strong></p>
<p>&nbsp;</p>
<p>&nbsp;</p>
<p><strong>OUR PURPOSE: </strong>The purpose of this movement is to shift the collective consciousness of humanity from one of inner scarcity to one of inner abundance.</p>
<p>&nbsp;</p>
<p>This is a change from the old normal to the new normal. A change from the ordinary to the extraordinary.</p>
<p>&nbsp;</p>
<p>Leading up to the COVID-19 pandemic of 2020, too many of us have turned our lives into the mundane. We have trivialized everything that truly matters to us; we have neglected the self, deprioritized our friends &amp; family, neglected our communities &amp; society, ignored economic wellbeing &amp; political justice, and compromised on ecological health. All of these are a result of our collective consciousness of inner scarcity.</p>
<p>&nbsp;</p>
<p>It is time to reimagine the future, one that involves balanced self, equitable humanity and thriving ecology. This will become possible only if we as humanity can evolve our consciousness to one of inner abundance.</p>
<p>&nbsp;</p>
<p>&nbsp;</p>
<p><strong>OUR DREAM</strong>: Elevated BEING, Spirit of Sharing, Abundant Humanity</p>
<p>&nbsp;</p>
<p>&nbsp;</p>
<p><strong>OUR CALLING</strong>:</p>
<p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
<ol>
<li><strong>Elevate the BEING</strong>: As a blossoming flower effortlessly attracts the bees, elevating our BEING attracts abundance. We help humanity move from the consciousness of inner scarcity to one of inner abundance. We also support in nurturing the spirit of sharing. The movement is taken forward by a global community of abundance leaders and ambassadors who will spark the consciousness of abundance, one community at a time</li>
</ol>
<p>&nbsp;</p>
<ol start="2">
<li><strong>Inclusive Content-Development</strong>: Wisdom is ever-known and Universal. We translate the timeless wisdom of abundance into practice in a boundaryless and democratic way. We harness the creative spirit of the human imagination, building and making available a constant stream of abundance-related learning resources with no financial encumbrance to users worldwide</li>
</ol>
<p>&nbsp;</p>
<ol start="3">
<li><strong>Harnessing Technology</strong>: Wealth is what it means to each. We help realize the Human Potential of technology minds across the globe to develop open-source tools and platforms that enable wealth flow across the planet. We inspire technology leaders to bring their resourcefulness and ingenuity to build intelligence that accelerate the expansion of abundance consciousness</li>
</ol>
<p>&nbsp;</p>
<p>&nbsp;</p>
<p><strong><br /> </strong></p>
<p><strong>&nbsp;</strong></p>
<p><strong>OUR LEADERSHIP STANDARDS</strong></p>
<p>&nbsp;</p>
<ol>
<li><strong>Infinite Possibilities Mindset</strong>: Where others see problems, I see possibilities. I recognize that life is a daily practice of becoming whole, and that journey takes me from inner scarcity to inner abundance. In my wholeness, I alter my relationship to adversities and see potential in each event and experience. Every upset for me is a setup in disguise.</li>
</ol>
<p>&nbsp;</p>
<ol start="2">
<li><strong>Love in Action</strong>: I am plenty, and I share and celebrate my plentifulness together with all. I love, I care, and I express that care through seeing others rejoice in abundance. I share for the joy of giving and from the knowingness that sharing is the act of nourishing the plant that bears eternal abundance. Playfulness and Adventure are expressions of my innate nature, and I unify the world through my daily acts of generosity.</li>
</ol>
<p>&nbsp;</p>
<ol start="3">
<li><strong>Seeing Divine in the Mundane</strong>: I am that child who sees the world with wonderment where every mundane object and event hides magic within. Each event is a parade of delight, a miracle offered by the world which opens me up with squeals of joy. Every occurrence has a deeper meaning which I am able to see each time I look more with my soul and less with my eyes. I am aware of the grander design within the little things, however trivial they may seem.</li>
</ol>
<p>&nbsp;</p>
<ol start="4">
<li><strong>Attitude of Gratitude</strong>: I recognize that the time and space I am offered is a benediction. It is my habit to express appreciation and say grace for all parts of my life, both the little and big things alike. I am aware of the contributions of those around me to my wellbeing and acknowledge them for their good intentions rather than judge them based on the results of their efforts/actions.</li>
</ol>
<p>&nbsp;</p>
<p>&nbsp;</p>
<p><strong>Partnership Standards that Govern your Creative Resources on the BEING Abundance Platform</strong></p>
<p>&nbsp;</p>
<p>The BEING Abundance Platform [hereafter referred to as &ldquo;the Platform&rdquo;] is a platform for people enriching people through sharing their resourcefulness. Like every other community, this Platform is held together by a set of codes (we like to call them Standards). Read on to learn further.</p>
<p>&nbsp;</p>
<p>&nbsp;</p>
<ul>
<li>As many thought-leaders in the East and West recognize, <strong><em>we are CHANNELS of art &amp; wisdom, not CREATORS of it</em></strong>.</li>
</ul>
<p>&nbsp;</p>
<p>What does that mean for you as a contributor on the Platform?</p>
<p>&nbsp;</p>
<p>It means that from the moment you share your resource [hereafter referred to as Wealth] on the Platform, the Wealth either becomes open source as specified under Creative Commons license <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, or &ndash; by default - gives rights until perpetuity to other users of the Platform to use your Wealth for growing abundance in their own lives or in the lives of others they serve. In both cases, we expect all users to acknowledge you as the contributor of that wealth.</p>
<p>&nbsp;</p>
<p>Upon sharing your Wealth on the Platform, you may assign it as open source as specified under Creative Commons license <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, or automatically rights until perpetuity will be extended by the Platform to other users.</p>
<p>&nbsp;</p>
<p>Additional note: Any Wealth that is co-developed together with Sujith Ravindran will automatically assume the nature of open source as specified under Creative Commons license <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>.</p>
<p>&nbsp;</p>
<p>&nbsp;</p>
<ul>
<li>We consider <strong><em>channelling as a divine ordainment</em></strong> that deserves to be widely celebrated.</li>
</ul>
<p>&nbsp;</p>
<p>This means, we will always boldly give credit to you and glowingly acknowledge you as the contributor of your Wealth. If you have a website or any other social media platform, we will happily annotate it under your Wealth. We know that will go farther than any of the old-school marketing strategies like <a href="https://www.investopedia.com/terms/b/bait-switch.asp">bait-and-switch</a>, tasters, teasers, upselling or enticement marketing to get users to come back for more. There is plenty to go around, and when users have experienced your Wealth, abundance will find its way to you.</p>
<p>&nbsp;</p>
<p>&nbsp;</p>
<ul>
<li>We recognize where people are in their process is <strong><em>the perfect place for them to be</em></strong>.</li>
</ul>
<p>&nbsp;</p>
<p>Which means, the marketing of your Wealth ends with awareness-creation of who you are and what you have to offer to the world. Any active sales is discouraged, and your spirit of service itself is the greatest sales tool. Please avoid promotional tactics with the intent to improve others, simply showing up in your authenticity is already irresistible to the human heart. When users are ready for you, they will notice you.</p>
<p>&nbsp;</p>
<p>&nbsp;</p>
<ul>
<li>We practice the <strong><em>standard of allowing</em></strong>.</li>
</ul>
<p>&nbsp;</p>
<p>We know that humanity is wise enough to decide what to consume and what to discard. We don&rsquo;t have to do it for them. We offer users what we have, and allow them the space to decide for themselves.</p>
<p>&nbsp;</p>
<p>Allowing also means no Wealth on this Platform will be offered with a price-tag attached to it, nor be offered free. Imposing your notion of value upon the user is applying force on the user, while offering your Wealth free is applying resistance to rewards that any may desire to flow our way.</p>
<p>&nbsp;</p>
<p>&nbsp;</p>
<ul>
<li><strong><em>We don&rsquo;t own others</em></strong>.</li>
</ul>
<p>&nbsp;</p>
<p>We feel the same way about networks and contact lists. Which means, we don&rsquo;t believe that we possess our network. We are part of one world which is united by the belief that our wealth comes from sincerely being in service to each other. So please, no putting up extra layers of log-ins into your Wealth to build separate contact lists. All users of the Platform will be clearly made aware that they will receive notifications of any non-profit and pro-bono offerings offered outside the Platform by you or any of the other contributors.</p>
<p>&nbsp;</p>
<p>Remember, when users of your Wealth love you, they will find you, subscribe to you and follow you.</p>
<p>&nbsp;</p>
<p>&nbsp;</p>
<ul>
<li><strong><em>There is enough abundance to go around.</em></strong></li>
</ul>
<p>&nbsp;</p>
<p>People are inherently generous in nature. They donate not only because they receive their worth, but also because they find meaning in sharing. And if you wish to receive a return for your contributions, we will equitably share part of the donations with you, because we know that is the right thing to do.</p>
<p>&nbsp;</p>
<p><em>* Please have patience, we are developing the technology needed to track usage of all the Wealth offered on the Platform.</em></p>
<p>&nbsp;</p>
<p>&nbsp;</p>
<ul>
<li>Lastly, this Platform is governed by the <strong><em>Creative Commons public license </em></strong><a href="https://creativecommons.org/licenses/by-sa/2.0/"><strong><em>CC-BY-SA</em></strong></a>. Any Wealth once shared on this Platform will automatically become subject to the same licensing. We invite you to take some time to study it. CC-BY-SA is illuminating, heart-opening and gives you a peek into the future of humanity.</li>
</ul>
<p>&nbsp;</p>
<p>&nbsp;</p>
<p>If you feel you cannot agree to these Partnership Standards, join us as a friend, not as a contributor. We will still love you 😊.</p>
<p>&nbsp;</p>
<p>Thank you.</p>
<p>&nbsp;</p>
<p>The BEING Abundance Team</p>
</div>