import { Component, OnInit } from '@angular/core';
import { CalendarOptions } from '@fullcalendar/angular'; // useful for typechecking


@Component({
  selector: 'app-calender',
  templateUrl: './calender.component.html',
  styleUrls: ['./calender.component.css']
})
export class CalenderComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    
  }
  ngAfterViewInit()
  {
    
  }
 

calendarOptions: CalendarOptions = {
    initialView: 'listWeek',
    googleCalendarApiKey: 'AIzaSyA51QMm99Dc_56xfZJfcFG2ONf3KGVsbb4',
    events: {
      googleCalendarId: 'abelchristo113@gmail.com'
    }
  };
}
