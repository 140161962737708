<section class="section" style="margin-top: 30px; padding-bottom: 1rem;" >
    <div class="container" style="text-align: center;">
        <div class="notification movement">
            <h1>Our Partners</h1>
        </div>
        <h2 class="movement-des">
            <h6>Meet our self-less institutional contributors for the BEING Abundance Movement</h6>
        </h2>
    </div>
</section>

<section class="section" style="padding: 0rem;">
    <div class="columns cust-width card">
        <div>
            <div>
                <a href="https://siamcomputing.com/" target="_blank">
                    <img src="../../../assets/img/siam-logo.png" width="400px;" style="padding: 10px;">
                 </a>
            </div>
        </div>
        <div>
            Siam Computing: A product development studio based out of Chennai, Siam Computing works with
            growth-stage startups to build solutions that disrupt their industry. For organisations across
            the global, Siam delivers industry-leading solutions that make an impact and help them navigate
            in their digital evolution journey
        </div>
    </div>
    <div class="columns cust-width card">
        <div>
            <a href="https://www.educatethefuturenow.com/" target="_blank">
                <img src="../../../assets/img/edu-logo.webp" width="500px;" style="padding: 10px;">
             </a>
        </div>
        <div>
            Educatethefuturenow: Founded by Briony, Educatethefuturenow works in business and education
            innovations and trains entrepreneurs in growth and quantum thinking. The organisation has been
            launching various events from 2008 with 30,000+ visitors. Briony has also created multiple
            innovative educational methods where cognitive skills, artistic expression and personal
            development are integrated in stories and fairy tales.
        </div>
    </div>

    <div class="columns cust-width card">
        <div>
            <a href="https://neurapses.com/" target="_blank">
                <img src="../../../assets/img/NT-blank.png" width="500px;" style="padding: 10px;">
             </a>
        </div>
        <div>
            Neurapses technologies: Neurapses technologies, based out of India and workspaces in London and
            Victoria, works on the next generation technologies and provides business solutions in
            Artificial Intelligence (AI) and Machine Learning (ML) for Banking, Legal, Retail, Financial and
            Healthcare flients. It also owns an educational brand called Mechatron Robotics for developing
            technological awareness among younger generation.
        </div>
    </div>

    <div class="columns cust-width card">
        <div>
            <a href="https://oneanddone.world/" target="_blank">
                <img src="../../../assets/img/one-one.webp"  width="300px;" style="padding: 10px;">
             </a>
           
        </div>
        <div>
            OneandDone is a Business Services Platform for professionals who travel and trade, helping and
            helps businesses owners, exhibitors and conference organisers reduce the stress and hassle of
            organising and paying for key… a complete digital engagement solution
        </div>
    </div>
</section>