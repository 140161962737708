<div class="tabs is-centered is-boxed" style="margin-top: 2rem;">
  <ul>
    <li class="tab is-active" (click)="openTab($event,'abundancebook')">
      <a>
        <span class="icon is-small"><i class="fas fa-book" aria-hidden="true"></i></span>
        <span>Abundance book </span>
      </a>
    </li>

    <li class="tab" (click)="openTab($event,'SelfattunementWheel')">
      <a>
        <span class="icon is-small"><i class="fas fa-film" aria-hidden="true"></i></span>
        <span>Self attunement Wheel</span>
      </a>
    </li>
    <li class="tab" (click)="openTab($event,'AbundanceVideos')">
      <a>
        <span class="icon is-small"><i class="fas fa-video" aria-hidden="true"></i></span>
        <span>Abundance Videos</span>
      </a>
    </li>
    <li class="tab" (click)="openTab($event,'Abundanceaudio')">
      <a>
        <span class="icon is-small"><i class="fas fa-music" aria-hidden="true"></i></span>
        <span>Abundance Audio</span>
      </a>
    </li>
    <li class="tab" (click)="openTab($event,'Abundanceanimations')">
      <a>
        <span class="icon is-small"><i class="far fa-file-alt" aria-hidden="true"></i></span>
        <span>Abundance animations</span>
      </a>
    </li>
    <li class="tab" (click)="openTab($event,'Symbols')">
      <a>
        <span class="icon is-small"><i class="far fa-file-alt" aria-hidden="true"></i></span>
        <span>Symbols and mandalas</span>
      </a>
    </li>
  </ul>
</div>

<section id="abundancebook" class="content-tab">
  <div class="container" style="text-align: center;">
    <div class="notification movement">
      <h1>Abundance book</h1>
    </div>
    <h2 class="movement-des">
      This is the paragraph where you can write more details about your product. Keep you user engaged by
      providing meaningful information. Remember that by this time, the user is curious, otherwise he wouldn't
      scroll to get here. Add a button if you want the user to see more.
    </h2>
    <div class="image-align">
      <figure class="image is-128x128" style="margin: auto;">
          
      </figure>
    </div>
    <div class="buttons abund-button">
      <button class="button is-warning ">
        <span class="icon">
          <i class="fab fa-amazon"></i>
        </span>
        <span>Get from Amazon</span>
      </button>
      <button class="button is-link">Donate And Access</button>
    </div>
  </div>
  <!-- <section class="hero is-medium has-carousel">
        <div class="my-element" class="hero-carousel">
            <div class="item-1">
               foewjfowpgpr
            </div>
            <div class="item-2">
              gwrg
            </div>
            <div class="item-3">
         gwr
            </div>
        </div>
        <div class="hero-head"></div>
        <div class="hero-body"></div>
        <div class="hero-foot"></div>
    </section> -->
  <section>
    <div class="container" style="text-align: center;">
      <div class="notification movement">
        <h1>Abundance E-book</h1>
      </div>
      <h2 class="movement-des">
        This is the paragraph where you can write more details about your product. Keep you user engaged by
        providing meaningful information. Remember that by this time, the user is curious, otherwise he wouldn't
        scroll to get here. Add a button if you want the user to see more.
      </h2>
      <div class="image-align">
        <figure class="image is-128x128" style="margin: auto;">
          <img src="../../../assets/img/abudance.jpg">
        </figure>
      </div>
      <div class="buttons abund-button">
        <button class="button is-link">Donate And Access</button>
      </div>
    </div>

  </section>

</section>
<section id="SelfattunementWheel" class="content-tab" style="display:none">
  <div class="container" style="text-align: center;">
    <div class="notification movement">
      <h1>Self attunement Wheel</h1>
    </div>
    <h2 class="movement-des">
      This is the paragraph where you can write more details about your product. Keep you user engaged by
      providing meaningful information. Remember that by this time, the user is curious, otherwise he wouldn't
      scroll to get here. Add a button if you want the user to see more.
    </h2>
    <div class="image-align">
      <figure class="image is-128x128" style="margin: auto;">
        <img src="../../../assets/img/Wheel.png">
      </figure>
    </div>
    <div class="buttons abund-button">
      <button class="button is-link">Donate And Access</button>
    </div>
  </div>

</section>
<section id="AbundanceVideos" class="content-tab" style="display:none">

  <div class="notification movement">
    <h1>Abundance Videos</h1>
  </div>
  <div class="columns" style=" padding: 40px;">
    <div class="column">
    <div class="card">
        <div class="card-image">
            <figure class="image is-square">
                <iframe class="has-ratio" width="640" height="360" src="https://www.youtube.com/embed/5vSjr0ZS3Hw" frameborder="0" allowfullscreen></iframe>
              </figure>
        </div>
        <div class="card-content">
          <div class="item__title">
            Mon titre 5
          </div>
          <div class="item__description">
            Ici une petite description pour tester le slider
          </div>
        </div>
      </div>
    </div>
    <div class="column">
      <div class="card">
        <div class="card-image">
            <figure class="image is-square">
                <iframe class="has-ratio" width="640" height="360" src="https://www.youtube.com/embed/7BSl7phXhAQ" frameborder="0" allowfullscreen></iframe>
              </figure>
        </div>
        <div class="card-content">
          <div class="item__title">
            Mon titre 5
          </div>
          <div class="item__description">
            Ici une petite description pour tester le slider
          </div>
        </div>
      </div>
    </div>

    <div class="column">
      <div class="card">
        <div class="card-image">
            <figure class="image is-square">
                <iframe class="has-ratio" width="640" height="360" src="https://www.youtube.com/embed/Hq6dAK4fCZ8" frameborder="0" allowfullscreen></iframe>
              </figure>
             
        </div>
        <div class="card-content">
          <div class="item__title">
            Mon titre 5
          </div>
          <div class="item__description">
            Ici une petite description pour tester le slider
          </div>
        </div>
      </div>
    </div>
    </div>
  <h2 class="movement-des">
    This is the paragraph where you can write more details about your product. Keep you user engaged by
    providing meaningful information. Remember that by this time, the user is curious, otherwise he wouldn't
    scroll to get here. Add a button if you want the user to see more.
  </h2>




</section>
<section id="Abundanceaudio" class="content-tab" style="display:none">

  <div class="notification movement">
    <h1>Abundance Audio</h1>
  </div>
  <h2 class="movement-des">
    <iframe style="height: 200px;" width="100%" height="600" scrolling="no" frameborder="no" allow="autoplay"
      src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/817715620&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"></iframe>
    <div
      style="font-size: 10px; color: #cccccc;line-break: anywhere;word-break: normal;overflow: hidden;white-space: nowrap;text-overflow: ellipsis; font-family: Interstate,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Garuda,Verdana,Tahoma,sans-serif;font-weight: 100;">
      <a href="https://soundcloud.com/ammth" title="aMMtH" target="_blank"
        style="color: #cccccc; text-decoration: none;">aMMtH</a> · <a
        href="https://soundcloud.com/ammth/ep-3-the-7-mystical-laws-of-abundance-with-sujith-ravindran"
        title="Ep. 3 - The 7 Mystical Laws of Abundance with Sujith Ravindran" target="_blank"
        style="color: #cccccc; text-decoration: none;">Ep. 3 - The 7 Mystical Laws of Abundance with Sujith
        Ravindran</a></div>
  </h2>




</section>
<section id="Abundanceanimations" class="content-tab" style="display:none">
  <div class="container" style="text-align: center;">
    <div class="notification movement">
      <h1>Abundance animations</h1>
    </div>
    <div class="columns">
      <div class="column">
      <div class="card">
          <div class="card-image">
              <figure class="image is-square">
                  <iframe class="has-ratio" width="640" height="360" src="https://www.youtube.com/embed/ut8IsLLn7qY" frameborder="0" allowfullscreen></iframe>
                </figure>
          </div>
          <div class="card-content">
            <div class="item__title">
              Mon titre 5
            </div>
            <div class="item__description">
              Ici une petite description pour tester le slider
            </div>
          </div>
        </div>
      </div>
      <div class="column">
        <div class="card">
          <div class="card-image">
              <figure class="image is-square">
                  <iframe class="has-ratio" width="640" height="360" src="https://www.youtube.com/embed/7BSl7phXhAQ" frameborder="0" allowfullscreen></iframe>
                </figure>
          </div>
          <div class="card-content">
            <div class="item__title">
              Mon titre 5
            </div>
            <div class="item__description">
              Ici une petite description pour tester le slider
            </div>
          </div>
        </div>
      </div>

      <div class="column">
        <div class="card">
          <div class="card-image">
              <figure class="image is-square">
                  <iframe class="has-ratio" width="640" height="360" src="https://www.youtube.com/embed/Hq6dAK4fCZ8" frameborder="0" allowfullscreen></iframe>
                </figure>
               
          </div>
          <div class="card-content">
            <div class="item__title">
              Mon titre 5
            </div>
            <div class="item__description">
              Ici une petite description pour tester le slider
            </div>
          </div>
        </div>
      </div>
      </div>
    <h2 class="movement-des">
      This is the paragraph where you can write more details about your product. Keep you user engaged by
      providing meaningful information. Remember that by this time, the user is curious, otherwise he wouldn't
      scroll to get here. Add a button if you want the user to see more.
    </h2>
    
  </div>

</section>
<section id="Symbols" class="content-tab" style="display:none">
  <div class="container" style="text-align: center;">
    <div class="notification movement">
      <h1>Abundance Symbols and mandalas</h1>
    </div>
    <h2 class="movement-des">
      This is the paragraph where you can write more details about your product. Keep you user engaged by
      providing meaningful information. Remember that by this time, the user is curious, otherwise he wouldn't
      scroll to get here. Add a button if you want the user to see more.
    </h2>
  </div>

</section>