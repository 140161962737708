<!-- <footer class="footer footer-image" style="background-color: #F3F3F3; padding:1rem">

  <div class="content has-text-centered">
    <p>
      &copy; 2020 <strong>Powered</strong> by <a href="https://beingabundance.com">Human Potential Fellowship Foundation</a>
    </p>

  </div>
  <nav class="level" style="max-width: 600px; margin: auto;">
    <div class="level-item has-text-centered">
      <div>
        <p class="level-item has-text-centered"><a href="/termsandconditions">Terms & Conditions</a></p>
      </div>
    </div>
    <div class="level-item has-text-centered">
      <div>
        <p class="level-item has-text-centered"><a href="/privacypolicy">Privacy Policy</a></p>
      </div>
    </div>
    <div class="level-item has-text-centered">
      <div>
        <p class="level-item has-text-centered"><a href="/contactus">Contact Us</a></p>
      </div>
    </div>
    <div class="level-item has-text-centered">
      <div>
        <p class="level-item has-text-centered"><a>Cookie Policy</a></p>
      </div>
    </div>
  </nav>

  <nav class="level" style="max-width: 600px; margin: auto;">
    <div class="level-item has-text-centered">
      <div class="navbar-item">
        <div class="navbar-item">
          <div class="field is-grouped">
            <p class="control">
              <a class="bd-tw-button button" style="background: #e4405f; color: #F3F3F3;"
                data-social-target="https://www.instagram.com/being_abundance/?igshid=1xdulsq4s8yuq" target="_blank"
                href="https://www.instagram.com/being_abundance/?igshid=1xdulsq4s8yuq">
                <span class="icon">
                  <i class="fab fa-instagram"></i>
                </span>
                <span>
                  Instagram
                </span>
              </a>
            </p>
            <p class="control">
              <a class="bd-tw-button button" style="background: #3b5998; color: #F3F3F3;"
                data-social-target="https://www.instagram.com/being_abundance/?igshid=1xdulsq4s8yuq" target="_blank"
                href="https://www.instagram.com/being_abundance/?igshid=1xdulsq4s8yuq">
                <span class="icon">
                  <i class="fab fa-facebook"></i>
                </span>
                <span>
                  Facebook
                </span>
              </a>
            </p>
            <p class="control">
              <a class="bd-tw-button button" style="background: #00acee; color: #F3F3F3;"
                data-social-target="https://www.instagram.com/being_abundance/?igshid=1xdulsq4s8yuq" target="_blank"
                href="https://www.instagram.com/being_abundance/?igshid=1xdulsq4s8yuq">
                <span class="icon">
                  <i class="fab fa-twitter"></i>
                </span>
                <span>
                  Twitter
                </span>
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  </nav>

</footer> -->

<nav class="navbar" style="min-height: 4.5rem;" role="navigation" aria-label="main navigation">
  <div class="navbar-brand" style="pointer-events: none; font-weight: bold;">
    <a class="navbar-item">
      Released and Powered by Human Potential Fellowship Foundation
    </a>
  </div>

  <div id="navbarBasicExample" class="navbar-menu">
    <div class="navbar-start">
      <a class="navbar-item" href="/termsandconditions">Terms & Conditions</a>
      <a class="navbar-item" href="/privacypolicy">Privacy Policy</a>
      <a class="navbar-item" href="/contactus">Contact Us</a>
      <a class="navbar-item" href="/contactus">Cookie Policy</a>
    </div>
    <div class="navbar-end">
      <div class="navbar-item">
        <div class="navbar-item">
          <div class="field is-grouped">
            <p class="control">
              <a class="bd-tw-button button" style="background: #e4405f; color: #F3F3F3;"
                data-social-target="https://www.instagram.com/being_abundance/?igshid=1xdulsq4s8yuq" target="_blank"
                href="https://www.instagram.com/being_abundance/?igshid=1xdulsq4s8yuq">
                <span class="icon">
                  <i class="fab fa-instagram"></i>
                </span>
                <span>
                  Instagram
                </span>
              </a>
            </p>
            <p class="control">
              <a class="bd-tw-button button" style="background: #3b5998; color: #F3F3F3;"
                data-social-target="https://www.facebook.com/beingabundance.org/" target="_blank"
                href="https://www.facebook.com/beingabundance.org/">
                <span class="icon">
                  <i class="fab fa-facebook"></i>
                </span>
                <span>
                  Facebook
                </span>
              </a>
            </p>
            <!-- <p class="control">
              <a class="bd-tw-button button" style="background: #00acee; color: #F3F3F3;"
                data-social-target="https://www.instagram.com/being_abundance/?igshid=1xdulsq4s8yuq" target="_blank"
                href="https://www.instagram.com/being_abundance/?igshid=1xdulsq4s8yuq">
                <span class="icon">
                  <i class="fab fa-twitter"></i>
                </span>
                <span>
                  Twitter
                </span>
              </a>
            </p> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</nav>

<!-- <div *ngIf="showCookies"  class="cookie-container">
  <p>
    We use cookies in this website to give you the best experience on our
    site To find out more, read our
    <a href="#">privacy policy</a> and <a href="#">cookie policy</a>.
  </p>

  <button class="cookie-btn" (click)="setCookies()">
    Okay
  </button>
</div> -->