<!-- <div class="join-now-stick">
    <h6 style="color: whitesmoke;">
        <span class="block-elem">Come Join Us!</span></h6>
</div> -->
<section class="hero hero-image ">
    <!-- Hero content: will be in the middle -->
    <div class="hero-body">
        <div class="container has-text-centered">
            <div class="title" style="color: #ffff; padding: 15px;">
                Welcome to the #BEINGAbundanceMovement
            </div>
            <div class="subtitle" style="color: #ffff; padding: 15px;">
                Elevated BEING, Spirit of Sharing, ONE Abundant Humanity
            </div>
        </div>
        <div class="videoimg">
            <figure>
                <!-- <iframe class="videoimg" src="https://www.youtube.com/embed/ztQKKFVcF4I?showinfo=0" frameborder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen></iframe> -->
                <iframe  class="videoimg" width="560" height="315" src="https://www.youtube.com/embed/g2XmDxtSJGY"
                    title="YouTube video player" frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen></iframe>
            </figure>
        </div>
        <div class="container has-text-centered">
            <div class="subtitle purp">
                The purpose of this movement is to shift the collective consciousness of humanity from one of inner
                scarcity to one of inner abundance
            </div>
        </div>



        <div>
            <!-- <figure class="image is-square banner-video">
                <iframe  width="400px" height="200px" src="https://www.youtube.com/embed/YE7VzlLtp-4?showinfo=0" frameborder="0" allowfullscreen></iframe>
            </figure> -->
        </div>

    </div>

</section>


<!-- <section class="section">
    <div class="container" style="text-align: center;">
        <div class="notification movement">
            <h1>#GlobalAbundanceMovement</h1>
        </div>
        <h2 class="movement-des">
            A Global Movement Envisioning an Abundant and Prosperous Humanity.
        </h2>
        <button class="button is-danger is-outlined">Know More</button>
    </div>

    <div class="card vision-card2" style="margin-top: 4%;">
        <div class="card-content">
            <div class="columns">
                <div class="column ">
                    <p class="movement" style="text-align: left;">Shift Your Being. Live Your Dharma. </p>
                    <p style="color: #FC2F3B;font-weight: bold;
                   font-size: 24px;">Be Abundant.</p>
                    <p>
                        Join the movement and receive updates on championing the global abundance movement by providing
                        your name and e-mail ID. Please note that we take privacy seriously and personal information
                        details remain safe.
                    </p>
                </div>

                <div class="column is-half is-mobile is-centered">

                    <div class="field">
                        <p class="control">
                            <input class="input is-rounded is-info" type="text" placeholder="Name">
                        </p>
                    </div>
                    <div class="field">
                        <p class="control has-icons-left has-icons-right">
                            <input class="input is-rounded is-info" type="email" placeholder="Email ID">
                            <span class="icon is-small is-left">
                                <i class="fas fa-envelope"></i>
                            </span>
                        </p>
                    </div>
                    <button class="button is-rounded is-medium is-fullwidth button-style2">READY TO MAKE THE
                        SHIFT!</button>
                </div>

            </div>
        </div>

    </div>
</section> -->

<section class="section">
    <div class="containers" style="text-align: center;">
        <div class="notification movement">
            <h1>Call to Action</h1>
        </div>
        <!-- <div class="columns visioncard3 ">
            <div class="column">
                <div>
                    <img src="../../../assets/img/pledge.svg">
                </div>
                <div class="font-heading-med" style="cursor: pointer;" (click)="ActivePledge()">
                    Take the
                    <br> ABUNDANCE PLEDGE
                </div>
                <button class="button font-heading-med is-primary">Large</button>
                <div class="font-sub-heading-med">
                    See More
                </div>
            </div>
            <div class="column" routerLink="resources" style="cursor: pointer;">
                <div>
                    <img src="../../../assets/img/Vector.svg">
                </div>
                <div class="font-heading-med">
                    Access the <br> ABUNDANCE RESOURCES
                </div>
                <div class="font-sub-heading-med">
                    See More
                </div>

            </div>
            <div class="column" routerLink="login" style="cursor: pointer;">
                <div>
                    <img src="../../../assets/img/principle.svg">
                </div>
                <div class="font-heading-med">
                    Join the <br> MOVEMENT
                </div>
                <div class="font-sub-heading-med">
                    See More
                </div>

            </div>
        </div> -->
        <div class="columns" style="padding: 3rem;">
            <div class="column">
                <div class="card cta" (click)="ActivePledge()">

                    <h2>
                        Take the ABUNDANCE PLEDGE
                    </h2>
                </div>
            </div>
            <div class="column">
                <div class="card cta" routerLink="/downloads">
                    Access the ABUNDANCE RESOURCES
                </div>
            </div>
            <div class="column">
                <div class="card cta" (click)="loginWithRedirect()">
                    Sign-up to receive daily Abundance bytes
                </div>
            </div>
        </div>
    </div>
</section>

<section class="hero is-bold dream-bg">
    <div class="hero-body">
        <div class="container" style="margin-top: 50px;">
            <div class="movement-des">
                <h1 style="font-weight: bold">Our Dream:</h1>
            </div>
            <h2 class="movement-des" style="font-weight: bold">
                Elevated BEING,Spirit of Sharing, Abundant Humanity
            </h2>
        </div>
    </div>
</section>

<section style="background-color: #47017F!important;margin-top: 12px; color: whitesmoke;">

    <div class="columns" style="margin-left:20px;margin-right: 20px;">
        <div class="column is-7" style="margin: 20px; margin-top: 80px;">
            <div class="container">
                <div class="notification movement"
                    style="margin-bottom: 3px;font-size: 25px; font-weight: bold; background-color: #47017F;color: #ffff;">
                    <h1 style="font-size: 25px;font-weight:bold">Our Calling</h1>
                </div>
                <section class="accordions">
                    <article class="accordion is-active">
                        <div class="card accordion-header toggle">
                            <h4>
                                Elevate the BEING:
                            </h4>
                        </div>
                        <div class="accordion-body">
                            <div class="accordion-content">
                                As the blossoming flower effortlessly attracts the bees, elevating the BEING attracts
                                abundance. We help humanity move from the consciousness of inner scarcity to one of
                                inner abundance, and nurture the spirit of sharing. The movement is taken forward by a
                                global community of abundance leaders and ambassadors who will spark the consciousness
                                of abundance, one community at a time
                            </div>
                        </div>
                    </article>
                    <article class="accordion">
                        <div class="card accordion-header">
                            <h4>
                                Content Creation:
                            </h4>
                            <button class="toggle buttonbg" aria-label="toggle"></button>
                        </div>
                        <div class="accordion-body">
                            <div class="accordion-content">
                                Wisdom is ever-known and Universal. We translate the timeless wisdom of abundance into
                                practice in a boundaryless and democratic way. We harness the creative spirit of the
                                human imagination, building and making available a constant stream of abundance-related
                                learning resources with no financial encumbrance to users worldwide
                            </div>
                        </div>
                    </article>
                    <article class="accordion">
                        <div class="card accordion-header">
                            <p>Harnessing Technology:</p>
                            <button class="toggle buttonbg" aria-label="toggle"></button>
                        </div>
                        <div class="accordion-body">
                            <div class="accordion-content">
                                Wealth is what it means to each. We realize the human potential of technology minds
                                across the globe to develop open-source tools and technology that enable wealth flow
                                across the planet. We inspire technology leaders to bring their resourcefulness and
                                ingenuity to build intelligence that accelerate the expansion of abundance consciousness
                            </div>
                        </div>
                    </article>
                </section>
            </div>


        </div>
        <div class="column" style="margin: 20px;">
            <figure class="image image is-5by4">
                <img style="object-fit: contain;" src="../.././../assets/img/Ourcalling.jpg">
            </figure>
        </div>
    </div>
</section>

<section>
    <div class="columns" style="margin-left:20px;margin-right: 20px;">
        <div class="column" style="margin: 20px;">
            <figure class="image image is-5by4">
                <img style="object-fit: contain;" src="../.././../assets/img/Ourstandards.jpg">
            </figure>
        </div>
        <div class="column is-7" style="margin: 20px;margin-top: 80px;">
            <div class="container">
                <div class="notification movement" style="margin-bottom: 3px;font-size: 20px;">
                    <h1 style="font-size: 25px;font-weight:bold">Our Leadership standards</h1>
                </div>
                <section class="accordions">
                    <article class="accordion is-active">
                        <div class="card accordion-header toggle">
                            <h4>
                                Infinite Possibilities Mindset:
                            </h4>
                        </div>
                        <div class="accordion-body">
                            <div class="accordion-content">
                                Where others see problems, I see possibilities. I recognize that life is a daily
                                practice of becoming whole, and that journey takes me from inner scarcity to inner
                                abundance. In my wholeness, I alter my relationship to adversities and see potential in
                                each event and experience. Every upset for me is a setup in disguise.
                            </div>
                        </div>
                    </article>
                    <article class="accordion">
                        <div class="card accordion-header">
                            <h4>
                                Love in Action:
                            </h4>
                            <button class="toggle buttonbg" aria-label="toggle"></button>
                        </div>
                        <div class="accordion-body">
                            <div class="accordion-content">
                                I am plenty, and I share and celebrate my plentifulness together with all. I love, I
                                care, and I express that care through seeing others rejoice in abundance. I share for
                                the joy of giving and from the knowingness that sharing is the act of nourishing the
                                plant that bears eternal abundance. Playfulness and Adventure are expressions of my
                                innate nature, and I unify the world through my daily acts of generosity.
                            </div>
                        </div>
                    </article>
                    <article class="accordion">
                        <div class="card accordion-header">
                            <p>Seeing Divine in the Mundane:</p>
                            <button class="toggle buttonbg" aria-label="toggle"></button>
                        </div>
                        <div class="accordion-body">
                            <div class="accordion-content">
                                I am that child who sees the world with wonderment where every mundane object and event
                                hides magic within. Each event is a parade of delight, a miracle offered by the world
                                which opens me up with squeals of joy. Every occurrence has a deeper meaning which I am
                                able to see each time I look more with my soul and less with my eyes. I am aware of the
                                grander design within the little things, however trivial they may seem.
                            </div>
                        </div>
                    </article>

                    <article class="accordion">
                        <div class="card accordion-header">
                            <p>Attitude of Gratitude:</p>
                            <button aria-label="toggle" class="toggle buttonbg">

                            </button>
                        </div>
                        <div class="accordion-body">
                            <div class="accordion-content">
                                I recognize that the time and space I am offered is a benediction. It is my habit to
                                express appreciation and say grace for all parts of my life, both the little and big
                                things alike. I am aware of the contributions of those around me to my wellbeing and
                                acknowledge them for their good intentions rather than judge them based on the results
                                of their efforts/actions.
                            </div>
                        </div>
                    </article>
                </section>

            </div>


        </div>
    </div>
</section>

<!-- <section class="vision">
    <div class="tile is-ancestor is-vertical ">
        <div class="tile is-parent ">
            <div class="tile is-child box">
                <div style="text-align: center;">
                    <div class="notification movement" style="font-size: 24px;">
                        <h1>Our Vision</h1>
                    </div>

                </div>
                <div style="text-align: center;">
                    <p>We envision to create a global community with conversations around Abundance and facilitate the
                        shift
                        of humanity's consciousness into one of Love, joy, and abundance.</p>
                    <p>We’re living at an interesting time, a time where a large part of humanity is at a standstill due
                        to
                        the COVID-19 pandemic. When we return to our normal lives post this break, we would’ve missed a
                        great lesson if we simply revert to our old ways of ‘normal’! We take this opportunity to invite
                        you
                        to shift to a 'new normal', a new normal where we’re driven by our purpose, generosity,
                        gratitude
                        and love.</p>
                    <p>
                        This site is dedicated to bring awareness of what’s possible when we embrace the laws of the
                        universe from the ancient science of Tantra, and also provide the resources necessary for
                        shifting
                        your being into the state of abundance.</p>
                    <p>
                        We want to share the message and impact many lives and see the magic that takes shape when each
                        individual operates from a sense of abundance in their lives. Enjoy the teachings, internalize
                        them
                        and contribute to the world in your own unique ways – just the way you were born to be.
                    </p>
                </div>

            </div>
        </div>
    </div>
</section> -->
<!-- <section class="section">
    <div class="containers" style="text-align: center;">
        <div class="notification movement">
            <h1>Stories</h1>
        </div>
        <div>
            For More Stories visit <a href="https://baos.dev/journal/">here</a>
        </div>
    </div>
</section> -->
<!-- <iframe src="https://shyaam2.typeform.com/to/tMrH7rvw" name="myFrame" style="width: 100%; height: 60vh;"></iframe> -->

<section class="quotecont ">
    <div class="notification quote">
        <h1>Abundance comes in many forms, do not limit your abundance by trying to control how it will flow, just know
            that it will come.</h1>
    </div>

    <div class="notification quote" style="font-size: 18px;">
        -Shelly Sullivan
    </div>
</section>
<img src="../../../assets/img/bg2.png">