import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { routing } from './app.routing';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './components/header/header.component';
import { HomeComponent } from './components/home/home.component';
import { FooterComponent } from './components/footer/footer.component';
import { ResourcesComponent } from './components/resources/resources.component';
import { RegisterComponent } from './components/register/register.component';
import { DynamicDialogComponent } from './components/dynamic-dialog/dynamic-dialog.component';
import { DynamicDialogContentDirective } from './components/dynamic-dialog/dynamic-dialog-content.directive';
import { DynamicDialogService } from './components/dynamic-dialog/dynamic-dialog.service';
import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule, DatePipe } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { AuxPagesComponent } from './components/aux-pages/aux-pages.component';
import { PrivacyPolicyComponent } from './components/privacy-policy/privacy-policy.component';
import { CardGameComponent } from './components/card-game/card-game.component';
import { DonationComponent } from './components/donation/donation.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ModalComponent } from './components/modal/modal.component';
import { ModalService } from './services/modal.service';
import { CardContentComponent } from './components/card-content/card-content.component';
import { AboutTeamComponent } from './components/about-team/about-team.component';
import { LoginComponent } from './components/login/login.component';
import { ConstitutionComponent } from './components/constitution/constitution.component';
import { CalenderComponent } from './components/calender/calender.component';
import { ShareButtonsModule } from 'ngx-sharebuttons/buttons';
import { ShareIconsModule } from 'ngx-sharebuttons/icons';
import { FullCalendarModule } from '@fullcalendar/angular'; // the main connector. must go first
import dayGridPlugin from '@fullcalendar/daygrid';
import listPlugin from '@fullcalendar/list';
import googleCalendarPlugin from '@fullcalendar/google-calendar';
import { PledgeComponent } from './components/pledge/pledge.component';
import { PreprodLayoutComponent } from './layout/preprod-layout/preprod-layout.component';
import { ProdLayoutComponent } from './layout/prod-layout/prod-layout.component';
import { LandingComponent } from './components/landing/landing.component';


import { AuthHttpInterceptor, AuthModule } from '@auth0/auth0-angular';
import { environment as env } from '../environments/environment';
import { CookieModule } from 'ngx-cookie';
import { OurpartnersComponent } from './components/ourpartners/ourpartners.component';
import { TempSignupComponent } from './components/temp-signup/temp-signup.component';
import { ProsperityComponent } from './components/prosperity/prosperity.component';
import { DownloadsComponent } from './components/downloads/downloads.component';
import { MediaComponent } from './components/media/media.component';
import { RetreatComponent } from './components/retreat/retreat.component';
import { AboutFoundation } from './components/about-foundation/about-foundation.component';

FullCalendarModule.registerPlugins([ // register FullCalendar plugins
  dayGridPlugin,
  listPlugin,
  googleCalendarPlugin
]);
@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    HomeComponent,
    FooterComponent,
    ResourcesComponent,
    RegisterComponent,
    DynamicDialogComponent,
    DynamicDialogContentDirective,
    AuxPagesComponent,
    PrivacyPolicyComponent,
    CardGameComponent,
    DonationComponent,
    ModalComponent,
    CardContentComponent,
    AboutTeamComponent,
    LoginComponent,
    ConstitutionComponent,
    CalenderComponent,
    PledgeComponent,
    PreprodLayoutComponent,
    ProdLayoutComponent,
    LandingComponent,
    OurpartnersComponent,
    TempSignupComponent,
    ProsperityComponent,
    DownloadsComponent,
    MediaComponent,
    RetreatComponent,
    AboutFoundation
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    routing,
    OverlayModule,
    CommonModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    FullCalendarModule,
    ShareButtonsModule,
    ShareIconsModule,
    AuthModule.forRoot({
      ...env.auth,
      scope: 'read:current_user create:current_user_metadata update:current_user_metadata',
      httpInterceptor: {
        ...env.httpInterceptor,
      },
    }),
    CookieModule.forRoot()

  ],

  providers: 
  [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthHttpInterceptor,
      multi: true,
    },DynamicDialogService, ModalService, DatePipe],
  bootstrap: [AppComponent],
  entryComponents: [DynamicDialogComponent, RegisterComponent, ModalComponent]
})
export class AppModule { }
