<div class="container card layout" style="margin-top:10vh;">
<p><strong class="heading">MILLION ZERO-KM ECONOMIES</strong></p>
<p>Globalization has failed the MSMEs in many ways. The trade barrier is such that when &nbsp;multinationals and the corporates that come in with humongous economies of the scale they bring to the table, the MSMEs have really no place in the trade.<br /> <br /> For instance, before Ikea had stepped in Padua, Italy, the city had incessantly and steadily depended on the medium and small sized artisans, furniture makers. Within just a decade, Ikea raced out every single MSME while also pricing exorbitantly, anywhere from 60% - 100% than their own products from Netherlands if you will, where the trade is competitive. Societies West and East are faced with an unprecedented erosion of wealth. Corporates pay a mere 20% or less in taxes while an MSME pays 40% and upwards in many parts of the world. Because of this, governments today don&rsquo;t have the kind of money to serve the human-kind with social welfare programs.</p>
<p>This means that the wealthy and the elites have had an undue representation in governance. When people have taken over governance, it has been labelled populism, and we are the ones as consumers that enable this, as we mindlessly consume from them. The issue with capitalists and corporates today is that they suck wealth out of you and hoard it in the swiss banks of the world. This won&rsquo;t stop unless you stop spending on them. This can only be controlled by having a conscious and meaningful consumption.<br /> <br /> To be conscious is to understand what goes on the label, where your money goes and to who you give that unprecedented power. Each one of us has the power not to choose to spend my money on them. The average middle class spends about 80% of its expenditure on 1% of brands or services. What would it be like if you pledged that at least 30% of your expenditure goes to the local services? Firstly, you reduce the transport of 1000s of kms of transiting these capitalistic products, that renders a massive positive impact on the environment. Secondly, you must keep sharing wealth and that is the only way wealth can multiply. When you buy from a local, your money helps in his/ her child&rsquo;s education, and isn&rsquo;t sitting idly in swiss banks.</p>
<p>Once you become mindful of where you spend your money, you are also choosing who you are giving social and political power to. That is the quantum of power you possess in your hands. When you spend all your monetary wealth in one place, you are centralizing power. When you spread your spending, you are decentralizing power.</p>
<p><strong class="heading"><br /> <br /> A Debtless, GRATITUDE ECONOMY</strong></p>
<p>28% of GDP goes towards servicing debt. This means approximately, anyone in our society, spends about 30% of their wages servicing their debts. Since this drives upward flow of wealth, the bottom of the pyramid is more debt laden, naturally. If you decide to go to university, you are debt laden. If you decide to start a business, you are debt laden. If you get married, you are debt laden. You decide to start a social initiative, you are debt laden. That is very convenient capitalistic mechanism to keep you in line. And the issue is, we are spending so much of wealth just in covering debt, not to mention the loss of freedom, the loss of sleep, etc.</p>
<p>In other words, debt is the surest way to upward flow of wealth, to impoverish people of the world. Dept is the perfect weapon to keep bottom of the pyramid in line.</p>
<p>Debt keeps you away from taking risks that can possible turn one&rsquo;s life around. It is a disservice to oneself, their family and the nation, to not get out of the grind, not get out of debt slavery.</p>
<p>Debtless economy is an essential key to prosperous humanity.</p>
<p>Instead of relying on banks, communities and families and friends can help each other, financially contributions. Our karmic conditioning has to be expanded to the overall worldly sentiments, than just within the immediate circle of family. From India, you must be inclined and be able to support and donate to someone in Vietnam or Uganda or wherever in the world. That expansion is the notion of gratitude, without boundaries. We are all a part of one family, one world. This expansion in consciousness to altruistically compassionate.</p>
<p>The idea of the gratitude economy is to replace financial debt with the debt of gratitude. If you have some money, and you would help somebody with that money, without loading that person with debt, that person will be eternally indebted to you.</p>
<p>That is gratitude debt activism.</p>
<p>This will have implications on the establishment, be it banks or governance. That is ok. That is how human evolution happens.</p>
<p>&nbsp;</p>
<p><strong class="heading">UNIVERSAL BASIC INCOME</strong></p>
<p>In the next decade, 60% of current jobs will become obsolete. Machines will take over the jobs. This would result in big unrest, riots, breaking of machines, lynching of machine owners, bourgeois, the political representatives. This is what people do when they are hungry.</p>
<p>The only way we can allow machines to become wealth-creators is if that wealth is distributed among humanity. Wealth owner cannot keep all the wealth.</p>
<p>Universal Basic Income is a necessity. There is no other way for humanity to survive. No other way in which humans and machines can live in harmony.</p>
<p>&nbsp;</p>
<p><strong class="heading">FUTURE OF WORK EMPOWERMENT</strong></p>
<p>Humanity will have to set itself up for the future of our vocations. It means that the billion + people at the bottom of the pyramid who wants dignity and economic empowerment, they have to be given the skills training they need so that they can follow their passions.</p>
<p>What we sow now, should benefit, as a better world, a better place to live in, for our successors to come.&nbsp;The BEING Abundance movement is working on an employability technology breakthrough that can help millions of youth understand where they stand and get to where they need to go.</p>
<p>&nbsp;</p>
</div>
