<!-- <section class="section" style="margin-top: 30px;">
    <div class="container" style="text-align: center;">
        <div class="notification movement">
            <h1>The Being Abundance Cards</h1>
        </div>
        <h2 class="movement-des">
            <h6>HOW TO USE THESE CARDS?</h6>
        </h2>
    </div>

    <div class="container">
        <div class="content">
            <ul>
                <li>There are countless ways to use these cards. You could draw one daily, or draw one when you
                    are
                    seeking guidance on an abundance-related choice.

                </li>
                <li>
                    Draw one alone or with your friends, draw for yourself or for another person.
                </li>
                <li>
                    You could draw a card with a clean mind, free of any intention. The card you draw will seed
                    you with
                    one. Or, each time you draw a card you can set an intention first and then draw a card. The
                    card will
                    support you in manifesting your intention.
                </li>
        
            </ul>
        </div>
    </div>
</section> -->

<!-- <div class="section" style="background-color: #24232c;" [hidden]="status">
    <div class="container">
        <div class="row">
            <div class="col-md-8 ml-auto mr-auto text-center">
    
                <h2 class="movement-des" style="color: whitesmoke;">
                    <h6>Click on the below deck to spread the cards!</h6>
                </h2>
            </div>
        </div>

        <div class="row" >
            <div class="col-md-8 ml-auto mr-auto text-center">
                <div class="buttons" style="text-align: center; display:block" (click)="onDonation()">
                    <a class=" button  is-danger is-inverted is-outlined">
                      Donate and Access
                    </a>
                </div>
            </div>
        </div>


    </div>
    <div id="images">
        <div class="container">
            <div class="row abd-card disabled">

                <ul (click)="showcardinRow()" class="cards-split">

                    <li class="card card-1"> <img src="../../../assets/img/Cardgame/FINAL_BEING-ABUNDANCE_YW.jpg"
                            class="img-rounded img-responsive ">
                    </li>

                    <li class="card card-2 "><img src="../../../assets/img/Cardgame/FINAL_BEING-ABUNDANCE_B.jpg"
                            class="img-rounded img-responsive">
                    </li>
                    <li class="card card-3"><img src="../../../assets/img/Cardgame/FINAL_BEING-ABUNDANCE_V.jpg"
                            class="img-rounded img-responsive">
                    </li>
                    <li class="card card-4"><img src="../../../assets/img/Cardgame/FINAL_BEING-ABUNDANCE_O.jpg"
                            class="img-rounded img-responsive">
                    </li>
                    <li class="card card-5"><img src="../../../assets/img/Cardgame/FINAL_BEING-ABUNDANCE_R.jpg"
                            class="img-rounded img-responsive">
                    </li>
                    <li class="card card-6"><img src="../../../assets/img/Cardgame/FINAL_BEING-ABUNDANCE_R.jpg"
                            class="img-rounded img-responsive">
                    </li>
                    <li class="card card-7"><img src="../../../assets/img/Cardgame/FINAL_BEING-ABUNDANCE_G.jpg"
                            class="img-rounded img-responsive">
                    </li>
                </ul>
            </div>

        </div>
    </div>
</div> -->



<!-- <div class="section" style="background-color: #24232c;" [hidden]="!status">
    <div class="container">
        <div class="row">
            <div class="col-md-8 ml-auto mr-auto text-center">

                <p class="movement-des" style="color: whitesmoke;">What you see below are the Cards for the Mystical
                    Law of Abundance. You're
                    invited to pick a card each day for the next 7 days and sit with the insight it offers to deepen
                    your understanding of the teaching!</p>
            </div>
        </div>
    </div>
    <div class="columns is-variable is-1-mobile is-0-tablet is-3-desktop is-12-widescreen is-2-fullhd"
        style="padding: 5rem 3rem;">
        <div class="column ">
            <img src="../../../assets/img/Law1/FINAL_BEING ABUNDANCE_O.jpg" class="img-thumbnail img-responsive"
                alt="Rounded Image" (click)="activateGame()">
            <p style="text-align: center; color: white;">
                LAW 1
            </p>
        </div>
        <div class="column disabled">
            <img src="../../../assets/img/Law2/Second Law_Echo_Blue.jpg" class="img-thumbnail img-responsive"
                alt="Rounded Image">
            <p style="text-align: center; color: white;">
                LAW 2
            </p>
        </div>
        <div class="column disabled">
            <img src="../../../assets/img/Law3/FINAL_BEING ABUNDANCE_R.jpg" class="img-thumbnail img-responsive"
                alt="Rounded Image">
            <p style="text-align: center; color: white;">
                LAW 3
            </p>
        </div>
        <div class="column disabled">
            <img src="../../../assets/img/Law4/FINAL_BEING ABUNDANCE_V.jpg" class="img-thumbnail img-responsive"
                alt="Rounded Image">
            <p style="text-align: center; color: white;">
                LAW 4
            </p>
        </div>
        <div class="column disabled">
            <img src="../../../assets/img/Law5/FINAL_BEING ABUNDANCE_I.jpg" class="img-thumbnail img-responsive"
                alt="Rounded Image">
            <p style="text-align: center; color: white;">
                LAW 5
            </p>
        </div>
        <div class="column disabled">
            <img src="../../../assets/img/Law6/FINAL_BEING ABUNDANCE_G.jpg" class="img-thumbnail img-responsive"
                alt="Rounded Image">
            <p style="text-align: center; color: white;">
                LAW 6
            </p>
        </div>
        <div class="column disabled">
            <img src="../../../assets/img/Law7/FINAL_BEING ABUNDANCE_YW.jpg" class="img-thumbnail img-responsive"
                alt="Rounded Image">
            <p style="text-align: center; color: white;">
                LAW 7
            </p>
        </div>
    </div>
</div> -->

<iframe src="https://beingabundance.com/" name="myFrame" style="width: 100%; height: 100vh;"></iframe>