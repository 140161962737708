import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './components/home/home.component';
import { ResourcesComponent } from './components/resources/resources.component';
import { AuxPagesComponent } from './components/aux-pages/aux-pages.component';
import { PrivacyPolicyComponent } from './components/privacy-policy/privacy-policy.component';
import { CardGameComponent } from './components/card-game/card-game.component';
import { DonationComponent } from './components/donation/donation.component';
import { RegisterComponent } from './components/register/register.component';
import { CardContentComponent } from './components/card-content/card-content.component';
import { AboutTeamComponent } from './components/about-team/about-team.component';
import { ContactUsComponent } from './components/contact-us/contact-us.component';
import { LoginComponent } from './components/login/login.component';
import { ConstitutionComponent } from './components/constitution/constitution.component';
import { CalenderComponent } from './components/calender/calender.component';
import { PledgeComponent } from './components/pledge/pledge.component';
import { OurpartnersComponent } from './components/ourpartners/ourpartners.component';
import { TempSignupComponent } from './components/temp-signup/temp-signup.component';
import { ProsperityComponent } from './components/prosperity/prosperity.component';
import {LandingComponent} from './components/landing/landing.component'
import { PreprodLayoutComponent } from './layout/preprod-layout/preprod-layout.component';
import { ProdLayoutComponent } from './layout/prod-layout/prod-layout.component';
import { DownloadsComponent } from './components/downloads/downloads.component';
import { MediaComponent } from './components/media/media.component';
import { AuthGuard } from '@auth0/auth0-angular';
import { RetreatComponent } from './components/retreat/retreat.component';
import { AboutFoundation } from './components/about-foundation/about-foundation.component';

const appRoutes: Routes = [
    { path: 'home', component: HomeComponent },
    { path: 'resources', component: ResourcesComponent },
    { path: 'downloads', component: DownloadsComponent },
    { path: 'media', component: MediaComponent },
    { path: 'privacypolicy', component: PrivacyPolicyComponent },
    { path: 'termsandconditions', component: AuxPagesComponent },
    { path: 'contactus', component: ContactUsComponent }, 
    { path: 'calender', component: CalenderComponent },    
    { path: 'cardgame', component: CardGameComponent },
    { path: 'donation', component: DonationComponent },
    { path: 'login', component: LoginComponent },
    {path: 'todayscard/:cardId', component:CardContentComponent},
    {path: 'ourteam', component:AboutTeamComponent},
    {path: 'constitution', component:ConstitutionComponent},
    {path: 'take-the-pledge', component:PledgeComponent},
    {path: 'ourpartners', component:OurpartnersComponent},
    {path: 'Ceremony', component:TempSignupComponent},
    {path: 'ceremony', component:TempSignupComponent},
    {path: 'prosperity', component:ProsperityComponent},
    {path: 'retreat', component:RetreatComponent},
    {path: 'about-foundation', component:AboutFoundation},
    { path: '**',  component: HomeComponent }
];


export const routing = RouterModule.forRoot(appRoutes);