<section class="section is-medium">
    
    <div class="container is-fluid">
     <div class="columns">
         <div class="column is is-full is-vcentered">
             <h1 style="color: white!important;">BEING Abundance Movement</h1>
             <h4  style="color:white!important;">One Abundant Humanity</h4>
             <p class="mb-3" style="color: #47017F!important;
             font-size: 23px;
             font-weight: bold;">We are going live on 13th April 2021. Sign-up below to join our Launch Ceremony!</p>
     <!--<button  class="button" style="background-color: #fc0254!important;
     border-color: #fc0254!important; color: #ffff; margin: 0rem 1rem;" (click)="auth.loginWithRedirect()" *ngIf="(auth.isAuthenticated$ | async) === false">Log in</button>!-->
                    
                     <a href="https://shyaam2.typeform.com/to/tMrH7rvw"> <button class="button" style="background-color: #47017F!important;
                             border-color: #47017F!important; color: #ffff;" type="button"
                             id="submit-button">SIGN UP</button></a>
 
                     <a href="https://podcasts.beingabundance.org/"> <button class="button" style="background-color: #fc0254!important;
                                 border-color: #fc0254!important; color: #ffff; margin: 0rem 1rem;" type="button"
                             id="submit-button">LISTEN TO PODCAST</button></a>
                          
      </div>
  </div>
 </div>
         
 </section>
