import { Component, OnInit } from '@angular/core';
import { bulmaCarousel } from 'bulma-carousel/dist/js/bulma-carousel.min.js';

@Component({
  selector: 'app-resources',
  templateUrl: './resources.component.html',
  styleUrls: ['./resources.component.css']
})

export class ResourcesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    // var element= document.querySelector(".my-element");
    // if (element && element) {
    //   // bulmaCarousel instance is available as element.bulmaCarousel
    // }

  }



  openTab(evt, tabName) {
    var i, x, tablinks;
    x = document.getElementsByClassName("content-tab");
    for (i = 0; i < x.length; i++) {
      x[i].style.display = "none";
    }
    tablinks = document.getElementsByClassName("tab");
    for (i = 0; i < x.length; i++) {
      tablinks[i].className = tablinks[i].className.replace(" is-active", "");
    }
    document.getElementById(tabName).style.display = "block";
    evt.currentTarget.className += " is-active";
  }
}