import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pledge',
  templateUrl: './pledge.component.html',
  styleUrls: ['./pledge.component.css']
})
export class PledgeComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
  downloadMyFile(){
    const link = document.createElement('a');
    link.setAttribute('target', '_blank');
    link.setAttribute('href', 'https://cdn.fbsbx.com/v/t59.2708-21/171702692_626373075427390_2721184799443604048_n.pdf/scroll-Final.pdf?_nc_cat=108&ccb=1-3&_nc_sid=0cab14&_nc_ohc=ydMUGvMzHeMAX9A4oCF&_nc_ht=cdn.fbsbx.com&oh=effe693f034c7a875595c8d442ddd4b5&oe=60763B37&dl=1');
    link.setAttribute('download', `pledge.pdf`);
    document.body.appendChild(link);
    link.click();
    link.remove();
}
}
