import { HttpClient } from '@angular/common/http';
import { Component, OnInit, Inject, } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { CookieService } from 'ngx-cookie';

import { DownloadsComponent } from '../downloads/downloads.component';

declare var $;
@Component({
  selector: 'app-donation',
  templateUrl: './donation.component.html',
  styleUrls: ['./donation.component.css']
})
export class DonationComponent extends DownloadsComponent{


  constructor(route: ActivatedRoute,
    router: Router,  
     cookieService: CookieService,
    public auth: AuthService, 
      http: HttpClient) {
      super(route,router,cookieService,auth,http)
    }
  

}
