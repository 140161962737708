<!-- <nav class="navbar header" role="navigation" aria-label="main navigation">
    <div style="text-align: center;">
        <a style="text-align: center;" href="home">
            <img class="logoimg" src="../../../assets/img/Group2.png">.org

        </a>

        <a role="button" class="navbar-burger burger" aria-label="menu" aria-expanded="false"
            data-target="navbarBasicExample">
            <span aria-hidden="true" style="color: #fff;"></span>
            <span aria-hidden="true" style="color: #fff;"></span>
            <span aria-hidden="true" style="color: #fff;"></span>
        </a>
    </div>
    <div id="navbarBasicExample" style="background-color:#47017F;" class="navbar-menu">
        <nav class="navbar-start">
            <div class="navbar-start">
                <a routerLink="/" class="navbar-item ba-headertxt">
                    Home
                </a>
                <a routerLink="resources" class="navbar-item ba-headertxt">
                    Resources
                </a>

                <a routerLink="cardgame" class="navbar-item ba-headertxt">
                    Play
                </a>
                <a class="navbar-item ba-headertxt">
                    Learning
                </a>

                <a class="navbar-item ba-headertxt">
                    Community
                </a>
                <div class="navbar-item has-dropdown is-hoverable">
                    <a class="navbar-link navbar-item ba-headertxt">
                        About
                    </a>
                    <div class="navbar-dropdown">
                        <a class="navbar-item">
                            Open Source
                        </a>
                    </div>
                </div>

            </div>
            <div class="navbar-end">
                <div class="navbar-item">
                    <div class="buttons">
                        <a (click)="registerModal()" class=" button is-rounded is-info is-inverted is-outlined">
                            <strong>Sign up/</strong><strong> <span></span> Sign in</strong>
                        </a>
                    </div>
                </div>
            </div>
        </nav>



    </div>
</nav> -->



<nav class="navbar is-fixed-top  is-hidden-touch" style="display: none;" role="navigation" aria-label="main navigation"
    style="background-color: #47017F!important;">
    <div class="navbar-brand">
        <a class="navbar-item" href="home" style="padding: 0px;">
            <img class="logoimg" src="../../../assets/img/newlogo.png" style="max-height: 5.25rem; padding-left: 3rem;">
        </a>

        <a role="button" class="navbar-burger burger" style="color: whitesmoke;" aria-label="menu" aria-expanded="false"
            data-target="navbarBasicExample">
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
        </a>
    </div>

    <div id="navbarBasicExample" style="background-color: #47017F!important;" class="navbar-menu">
        <div class="navbar-start" style="flex-grow: 1; justify-content: center;">
            <a routerLink="home" class="navbar-item ba-headertxt">
                Home
            </a>
            <div class="navbar-item has-dropdown is-hoverable">
                <a class="navbar-link">
                    Resources
                </a>
                <div class="navbar-dropdown is-boxed">
                    <a class="navbar-item" routerLink="/downloads" target="_blank">
                        Downloads
                    </a>
                    <a class="navbar-item" routerLink="/media" target="_blank">
                        Media
                    </a>
                    <a class="navbar-item" href="https://podcasts.beingabundance.org/" target="_blank">
                        Podcasts
                    </a>
                    <a (click)="cardGame()" class="navbar-item" target="_blank">
                        Play
                    </a>
                </div>
            </div>

            <!-- <a (click)="cardGame()"  class="navbar-item ba-headertxt">
                Play
            </a> -->
            <a routerLink="/retreat" class="navbar-item ba-headertxt">
                Retreat 2024
            </a>
            <a routerLink="/prosperity" class="navbar-item ba-headertxt">
                Our Prosperity Causes
            </a>
            <!-- <a routerLink="calender" class="navbar-item ba-headertxt">
                Events
            </a> -->


            <div class="navbar-item has-dropdown is-hoverable">
                <a class="navbar-link">
                    About Us
                </a>
                <div class="navbar-dropdown is-boxed">
                    <a class="navbar-item" target="_blank" href="/about-foundation">
                        About the Foundation 
                    </a>
                    <a class="navbar-item" routerLink="/ourteam">
                        Our Team
                    </a>
                    <a class="navbar-item" routerLink="/constitution">
                        Constitution
                    </a>
                    <a class="navbar-item" routerLink="/ourpartners">
                        Our Partners
                    </a>
                </div>
            </div>
        </div>
        <div class="navbar-end" *ngIf="(auth.isAuthenticated$ | async) === false">
            <div class="navbar-item">
                <div class="btn_wrap button is-info is-inverted is-outlined">
                    <span class="spanopen" >Share</span>
                    <div class="container">
                        <share-buttons [theme]="'circles-light'" [include]="['facebook','twitter','whatsapp','email']"
                        [show]="4" [size]="-8" [title]="'Being Abundace'" [url]="'https://abundance-redesign.web.app/'"
                        [description]="'Come Be A Part'"></share-buttons>
                    </div>
                </div>
                <div class="buttons">
                    <a (click)="loginWithRedirect()" class=" button mbl-btn is-info is-inverted is-outlined">
                        <strong>Sign up / Login</strong>
                    </a>

                </div>
            </div>
         </div>
         <div class="navbar-end" *ngIf="auth.user$ | async as user">

            <div class="navbar-item has-dropdown is-hoverable">
                <a class="navbar-link">
                    <div>
                     
                        <a style="padding: 4px;" role="button">
                            <img style="border-radius: 50%;height: 100px;"  [src]="user.picture"></a>
                    </div>
                </a>
                <div class="navbar-dropdown is-right" style="background: #F1FAFF;border: 1px solid lightgray;">
                    <a class="navbar-item">
                        {{user.name }}
                    </a>
                    <hr class="navbar-divider">
                    <a class="navbar-item" (click)="logout()">
                        Log out
                    </a>
                </div>
            </div>
        </div>
    </div>
</nav>
<nav class="navbar is-hidden-desktop">
    <div class="navbar-brand" style="background-color:  #47017F;">
        <a class="navbar-item" href="home" style="padding: 0px;">
            <img class="logoimg" src="../../../assets/img/newlogo.png" style="max-height: 5.25rem; padding-left: 3rem;">
        </a>
        <div class="navbar-burger" style="color: #ffff;" data-target="navbarExampleTransparentExample">
            <span></span>
            <span></span>
            <span></span>
        </div>
    </div>

    <div id="navbarExampleTransparentExample" class="navbar-menu" style="padding: 0rem;">
        <div class="navbar-start">
            <div class="navbar-item has-dropdown is-hoverable">
                <div class="navbar-dropdown is-boxed">
                    <a class="navbar-item" routerLink="/downloads">
                        Downloads
                    </a>
                    <a class="navbar-item" routerLink="/media">
                        Media
                    </a>
                    <a class="navbar-item" href="https://podcasts.beingabundance.org/" target="_blank">
                        Podcasts
                    </a>
                    <a (click)="cardGame()" class="navbar-item">
                        Play
                    </a>

                    <a class="navbar-item" target="_blank" href="/about-foundation">
                        About Foundation
                    </a>
                    <a class="navbar-item" routerLink="/ourteam">
                        Our Team
                    </a>
                    <a routerLink="/prosperity" class="navbar-item ba-headertxt">
                        Our Prosperity Causes
                    </a>
                    <a routerLink="/retreat" class="navbar-item ba-headertxt">
                        Retreat 2024
                    </a>
                    <a class="navbar-item" routerLink="/constitution">
                        Constitution
                    </a>
                    <a class="navbar-item" routerLink="/ourpartners">
                        Our Partners
                    </a>
                    <a (click)="loginWithRedirect()" class=" button mbl-btn is-info is-inverted is-outlined">
                        <strong>Sign up / Login</strong>
                    </a>
                </div>
            </div>
        </div>
        <div class="navbar-end" *ngIf="(auth.isAuthenticated$ | async) === true">

            <div class="navbar-item has-dropdown is-hoverable">
                <a class="navbar-link">
                    <div>
                        <!-- {{ user.name }} -->
                        <a style="padding: 4px;" role="button">
                            <img style="border-radius: 50%;height: 100px;" src="../../../assets/img/user.svg"></a>
                    </div>
                </a>
                <div class="navbar-dropdown is-right" style="background: #F1FAFF;border: 1px solid lightgray;">
                    <!-- <a class="navbar-item">
                        {{ user.name }}
                    </a> -->
                    <hr class="navbar-divider">
                    <a class="navbar-item" (click)="logout()">
                        Log out
                    </a>
                </div>
            </div>
        </div>
    </div>
</nav>