import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-aux-pages',
  templateUrl: './aux-pages.component.html',
  styleUrls: ['./aux-pages.component.css']
})
export class AuxPagesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
