import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { ModalService } from 'src/app/services/modal.service';
import { Modal } from '../../helper/modal.model';
import { Subject, Observable } from 'rxjs';
@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.css']
})

export class ModalComponent  {

  
  modalDetails;
  modalActiveMode: boolean = false;
  private result$ = new Subject<any>();
  constructor(private modalsvc: ModalService) {
   
  }
  
  ngOnInit() {
    this.modalsvc.modalState.subscribe((state) => {
      this.modalDetails = state;
      if (this.modalDetails.active == true) {
        this.modalActiveMode = true;
      }
    })
  }

  onConfirmDelete(){
    this.modalsvc.statusConfirmed(true);
    this.modalActiveMode = false;
    }

  onDismiss(){
    this.modalsvc.statusConfirmed(false);
    this.modalActiveMode = false;
  }
}
