<section>
    <div class="columns" style="width: 800px; margin: 3rem auto; padding: 3rem 0rem;">
        <div class="column ">
            <div class="inner-content">
                <p style="color:#0E0066;font-weight: 600; font-size: 44px;">Hello!</p>
                <p style="color:#0E0066; font-weight: 600;font-size: 20px;"> Nice to meet you.</p>
                <p style="margin-top:20px;">Do you any questions? Ideas? </p>
                <p>Thoughts? </p>
                <p> Do you want to be a part of the </p>
                <p> movement? Want to collaborate?</p>
                <p>Feel free to send us a message.</p>
                <!-- <p style="margin-top:20px; ">Or drop a message on</p>
                <a href="#">
                    <img class="socialshare" src="../../../assets/img/whatsapp 1.png">
                </a>
                <a href="#">
                    <img class="socialshare" src="../../../assets/img/facebook 1.png">
                </a> -->
            </div>
        </div>
        <div class="column ">
            <div class="inner-content">
                <div class="card">
                    <div class="has-text-centered" style=" padding: 40px;">
                        <form action="https://mailthis.to/beingabundace" method="POST"
                            encType="multipart/form-data">

                            <div class="control ">
                                <input class="input " type="text" name="name" placeholder="Name">
                            </div>
                            <div class="control">
                                <input class="input " type="text" name="_replyto" placeholder="Email ID">
                            </div>
                            <div class="control">
                                <textarea name="message" class="textarea has-fixed-size"
                                    placeholder="Your question/message"></textarea>
                            </div>
                            <input type="hidden" name="_subject" value="Contact form submitted">
                            <div class="control">
                                <button type="submit" class="button is-danger is-rounded button ">Submit</button>
                            </div>
                            <!-- <input type="text" name="name" placeholder="Your name">
                            <input type="email" name="_replyto" placeholder="Your email">
                            <textarea name="message" placeholder="Enter your message here"></textarea>
                            <input type="file" name="file" placeholder="Attachments (optional)">
                            <input type="hidden" name="_subject" value="Contact form submitted">
                            <input type="hidden" name="_after" value="https://beingabundance.org/">
                            <input type="hidden" name="_honeypot" value="">
                            <input type="hidden" name="_confirmation" value="">
                            <input type="submit" value="Send"> -->
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>