<div class="modal is-active" style="padding-left: 1rem; padding-right: 1rem" [@animation]="{
  value: animationState,
  params: {
    timing: dialogConfig.containerAnimationTiming,
    delayChild: dialogConfig.animationChildDelay
  }
}" (@animation.start)="animationStateChanged.emit($event)" (@animation.done)="animationStateChanged.emit($event)">
    <div class="modal-background" (click)="onModalBackgroundClick()"></div>
    <div class="modal-card" [@zoom]="{
    value: animationState == 'enter' ? 'in' : 'out',
    params: { timing: dialogConfig.contentAnimationTiming }
  }">
        <!-- <header class="modal-card-head">
            <p class="modal-card-title">{{ dialogConfig.header }}</p>
            <button class="delete" aria-label="close" *ngIf="dialogConfig.closable" (click)="onCloseClick()"></button>
        </header> -->
        <!-- <section class="modal-card-body">
            <ng-template appDynamicDialogContent></ng-template>
        </section> -->

        <ng-template appDynamicDialogContent></ng-template>
    </div>
</div>