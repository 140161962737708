import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  showCookies :boolean =true;
  constructor() { }

  ngOnInit(): void {
    let cookies= localStorage.getItem("cookieBannerDisplayed")
    
    if(cookies){
      this.showCookies = false;
    }

 
    // const cookieContainer = document.querySelector(".cookie-container");
    // const cookieButton = document.querySelector(".cookie-btn");
    
    // cookieButton.addEventListener("click", () => {
    //   cookieContainer.classList.remove("active");
    //   localStorage.setItem("cookieBannerDisplayed", "true");
    // });
    
    // setTimeout(() => {
    //   if (!localStorage.getItem("cookieBannerDisplayed")) {
    //     cookieContainer.classList.add("active");
    //   }
    // }, 2000);
  }

  setCookies(){
    this.showCookies = false;
    localStorage.setItem("cookieBannerDisplayed", "true");
  }

}
