import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthenticationService } from 'src/app/services/authentication.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

 
  signupForm: FormGroup;
  loginForm: FormGroup;
  submit = false;
  returnUrl: String;
  showlogin: boolean = false;
  signupArray = []
  constructor(private formBuilder: FormBuilder, private authScv: AuthenticationService) {
  }

  ngOnInit(): void {
    this.signupForm = this.formBuilder.group({
      firstName: ['', [Validators.required]],
      email: ['', [Validators.required]],
      password: ['', [Validators.required]],
      mobileNumber: ['', [Validators.required]],
      lastName: [''],
      userName: [''],

    })
    this.loginForm = this.formBuilder.group({
      email: '',
      password:''
  })
  }

  registermode() {
    this.showlogin =  !this.showlogin;
  }

  onsubmitLogin() {
    this.authScv.login(this.loginForm.value)
  }


}
