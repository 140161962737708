import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'abundance1';

  // const sound = new Howl({
  //   src: ['../assets/mp3/bgaudio.mp3', '../assets/mp3/bgaudio.mp3']
  // });
  onActivate(event) {
    window.scroll(0, 0);
  }
  ngOnInit() {
    // Play the sound.
    let cc = window as any;
    cc.cookieconsent.initialise({
      palette: {
        popup: {
          background: "#47017F!important"
        },
        button: {
          background: "white",
          text: "#164969"
        }
      },
      theme: "classic",
      content: {
        message: ' We use cookies in this website to give you the best experience on our site To find out more, read our',
        dismiss:  'I Accept',
        link:  'cookie policy'
        // href: environment.Frontend + "/dataprivacy" 
      }
    });
  
  }
}

