<div class="tabs is-centered is-boxed" style="margin-top: 2rem;">
  <ul>
    <li class="tab" (click)="openTab($event,'AbundanceVideos')">
      <a>
        <span class="icon is-small"><i class="fas fa-video" aria-hidden="true"></i></span>
        <span>Abundance Videos</span>
      </a>
    </li>
    <li class="tab" (click)="openTab($event,'Abundanceaudio')">
      <a>
        <span class="icon is-small"><i class="fas fa-music" aria-hidden="true"></i></span>
        <span>Abundance Audio</span>
      </a>
    </li>
  </ul>
</div>

<section id="AbundanceVideos" class="content-tab">

  <div class="notification movement">
    <h1>Abundance Videos</h1>
  </div>
  <div class="columns" style=" padding: 40px;">
    <div class="column">
      <div class="card">
        <div class="card-image">
          <figure class="image is-square">
            <iframe class="has-ratio" width="640" height="360" src="https://www.youtube.com/embed/ztQKKFVcF4I"
              frameborder="0" allowfullscreen></iframe>
          </figure>
        </div>
        <div class="card-content">
          <div class="item__title">
            Uncovering the Levers of True Happiness
          </div>
          <div class="item__description">
            Is happiness really what you think it is? Sujith says that there is more to happiness than quality of life
            and pleasure - it is a natural emotion built on four important levers, devoid of any externalities.
          </div>
        </div>
      </div>
    </div>
    <div class="column">
      <div class="card">
        <div class="card-image">
          <figure class="image is-square">
            <iframe class="has-ratio" width="640" height="360" src="https://www.youtube.com/embed/5vSjr0ZS3Hw"
              frameborder="0" allowfullscreen></iframe>
          </figure>

        </div>
        <div class="card-content">
          <div class="item__title">
            The 7 Mystical Laws of Abundance
          </div>
          <div class="item__description">
            An Introduction on the 7 Mystical laws of Abundance presented at the Unity community
          </div>
        </div>
      </div>
    </div>

    <div class="column">
      <div class="card">
        <div class="card-image">
          <figure class="image is-square">
            <iframe class="has-ratio" width="640" height="360" src="https://www.youtube.com/embed/t87L8BDZkAk"
              frameborder="0" allowfullscreen></iframe>
          </figure>

        </div>
        <div class="card-content">
          <div class="item__title">
            5 Keys to a Prosperous Humanity
          </div>
          <div class="item__description">
            An inquiry into our collective prosperity
          </div>
        </div>
      </div>
    </div>
    <div class="column">
      <div class="card">
        <div class="card-image">
          <figure class="image is-square">
            <iframe class="has-ratio" width="640" height="360" src="https://www.youtube.com/embed/W-4bcJy9YFM"
              frameborder="0" allowfullscreen></iframe>
          </figure>
        </div>
        <div class="card-content">
          <div class="item__title">
            Short teaser on Abundance
          </div>
          <div class="item__description">
            A flash-interview by Debora T. Stenta with Sujith Ravindran on the way from Puttaparthi to Bangalore, India
          </div>
        </div>
      </div>
    </div>
    <div class="column">
      <div class="card">
        <div class="card-image">
          <figure class="image is-square">
            <iframe class="has-ratio" width="640" height="360" src="https://www.youtube.com/embed/9nMYEzczvws"
              frameborder="0" allowfullscreen></iframe>
          </figure>
        </div>
        <div class="card-content">
          <div class="item__title">
            The 1st Key of Abundance: Introduction with Sujith and Briony
          </div>
          <div class="item__description">
            A discussion on the first key/law of Abundance between Sujith Ravindran and Briony, from April 2020.
          </div>
        </div>
      </div>
    </div>

  </div>
  <!-- <h2 class="movement-des">
    This is the paragraph where you can write more details about your product. Keep you user engaged by
    providing meaningful information. Remember that by this time, the user is curious, otherwise he wouldn't
    scroll to get here. Add a button if you want the user to see more.
  </h2> -->




</section>
<section id="Abundanceaudio" class="content-tab" style="display:none">

  <div class="notification movement">
    <h1>Abundance Audio</h1>
  </div>
  <h2 class="movement-des">
    <iframe style="height: 200px;" width="100%" height="600" scrolling="no" frameborder="no" allow="autoplay"
      src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/817715620&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"></iframe>
    <div
      style="font-size: 10px; color: #cccccc;line-break: anywhere;word-break: normal;overflow: hidden;white-space: nowrap;text-overflow: ellipsis; font-family: Interstate,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Garuda,Verdana,Tahoma,sans-serif;font-weight: 100;">
      <a href="https://soundcloud.com/ammth" title="aMMtH" target="_blank"
        style="color: #cccccc; text-decoration: none;">aMMtH</a> · <a
        href="https://soundcloud.com/ammth/ep-3-the-7-mystical-laws-of-abundance-with-sujith-ravindran"
        title="Ep. 3 - The 7 Mystical Laws of Abundance with Sujith Ravindran" target="_blank"
        style="color: #cccccc; text-decoration: none;">Ep. 3 - The 7 Mystical Laws of Abundance with Sujith
        Ravindran</a>
    </div>

  </h2>

  <h2 class="movement-des">
    <iframe width="100%" height="300" scrolling="no" frameborder="no" allow="autoplay"
      src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/211656976&color=%23352c26&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"></iframe>
    <div
      style="font-size: 10px; color: #cccccc;line-break: anywhere;word-break: normal;overflow: hidden;white-space: nowrap;text-overflow: ellipsis; font-family: Interstate,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Garuda,Verdana,Tahoma,sans-serif;font-weight: 100;">
      <a href="https://soundcloud.com/koen-bugter" title="Koen Bugter" target="_blank"
        style="color: #cccccc; text-decoration: none;">Koen Bugter</a> · <a
        href="https://soundcloud.com/koen-bugter/sujith-ravindran-about-freedom-part-1"
        title="Sujith Ravindran  About Freedom Part 1" target="_blank"
        style="color: #cccccc; text-decoration: none;">Sujith Ravindran About Freedom Part 1</a></div>
  </h2>




</section>